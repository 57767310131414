import React, { Component } from 'react'
import Routes from './Routes'
import { withLocalize } from 'react-localize-redux'
import de from './lang/de.json'
import en from './lang/en.json'
import fr from './lang/fr.json'
import fa from './lang/fa.json'
import gu from './lang/gu.json'
import he from './lang/he.json'
import hi from './lang/hi.json'
import hu from './lang/hu.json'
import id from './lang/id.json'
import it from './lang/it.json'
import ja from './lang/ja.json'
import urdu from './lang/urdu.json'
import arabic from './lang/arabic.json'
import ku from './lang/kurdish.json'
import km from './lang/khmer.json'
import ko from './lang/ko.json'
import mn from './lang/mn.json'
import my from './lang/my.json'
import ne from './lang/ne.json'
import pt from './lang/pt.json'
import ru from './lang/ru.json'
import sq from './lang/sq.json'
import sr from './lang/sr.json'
import te from './lang/te.json'
import th from './lang/th.json'
import tr from './lang/tr.json'
import zh from './lang/zh.json'
import am from './lang/amharic.json'
import spanish from './lang/es.json'
import Loader from './Layout/Loader'
import Axios from 'axios'
import instance from './axios'
import { connect } from 'react-redux'
import { ServerInfo } from './Actions/serverSetting'

const onMissingTranslation = ({ translationId }) => translationId
// const location= {
//   pathname:"monitoring"
// }
class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      whiteLabling: '',
      callResponse: false,
      loading: false,
      isDown: false,
      mainClass: ''
    }
    this.props.initialize({
      languages: [
        { name: 'Albanian', code: 'sq' },
        { name: 'Amharic', code: 'am' },
        { name: 'Burmese', code: 'my' },
        { name: 'Chinese', code: 'zh' },
        { name: 'English', code: 'en' },
        { name: 'German', code: 'de' },
        { name: 'French', code: 'fr' },
        { name: 'Gujarati', code: 'gu' },
        { name: 'Hebrew', code: 'he' },
        { name: 'Hindi', code: 'hi' },
        { name: 'Hungarian', code: 'hu' },
        { name: 'Indonesian', code: 'id' },
        { name: 'Italian', code: 'it' },
        { name: 'Japanese', code: 'ja' },
        { name: 'Arabic', code: 'ar' },
        { name: 'Central Khmer', code: 'km' },
        { name: 'Korean', code: 'ko' },
        { name: 'Kurdish', code: 'ku' },
        { name: 'Mongolian', code: 'mn' },
        { name: 'Nepali', code: 'ne' },
        { name: 'Persian', code: 'fa' },
        { name: 'Portuguese', code: 'pt' },
        { name: 'Russian', code: 'ru' },
        { name: 'Serbian', code: 'sr' },
        { name: 'Telugu', code: 'te' },
        { name: 'Thai', code: 'th' },
        { name: 'Turkish', code: 'tr' },
        { name: 'Urdu', code: 'ur' },
        { name: 'Spanish', code: 'es' },
      ],
      //translation: en,
      options: {
        renderToStaticMarkup: false,
        defaultLanguage: 'en',
        onMissingTranslation
      }
    })

    this.props.addTranslationForLanguage(am, 'am')
    this.props.addTranslationForLanguage(en, 'en')
    this.props.addTranslationForLanguage(de, 'de')
    this.props.addTranslationForLanguage(fr, 'fr')
    this.props.addTranslationForLanguage(fa, 'fa')
    this.props.addTranslationForLanguage(gu, 'gu')
    this.props.addTranslationForLanguage(he, 'he')
    this.props.addTranslationForLanguage(hi, 'hi')
    this.props.addTranslationForLanguage(hu, 'hu')
    this.props.addTranslationForLanguage(id, 'id')
    this.props.addTranslationForLanguage(it, 'it')
    this.props.addTranslationForLanguage(ja, 'ja')
    this.props.addTranslationForLanguage(km, 'km')
    this.props.addTranslationForLanguage(ko, 'ko')
    this.props.addTranslationForLanguage(ku, 'ku')
    this.props.addTranslationForLanguage(mn, 'mn')
    this.props.addTranslationForLanguage(my, 'my')
    this.props.addTranslationForLanguage(ne, 'ne')
    this.props.addTranslationForLanguage(pt, 'pt')
    this.props.addTranslationForLanguage(ru, 'ru')
    this.props.addTranslationForLanguage(sq, 'sq')
    this.props.addTranslationForLanguage(sr, 'sr')
    this.props.addTranslationForLanguage(te, 'te')
    this.props.addTranslationForLanguage(th, 'th')
    this.props.addTranslationForLanguage(tr, 'tr')
    this.props.addTranslationForLanguage(zh, 'zh')
    this.props.addTranslationForLanguage(urdu, 'ur')
    this.props.addTranslationForLanguage(arabic, 'ar')
    this.props.addTranslationForLanguage(spanish, 'es')
  }

  getImage = async (id, fieldName) => {
    try {
      const response = await Axios.get(`/api/media/public/${id}`, {
        responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
      });
      const filename = id;
      const parts = filename.split('.');
      const extension = parts[parts.length - 1];
  
      if (response.status === 200) {
        // Create a Blob from the array buffer
        const blob = new Blob([response.data], { type: `image/${extension}` });
  
        // Read the blob using FileReader
        const reader = new FileReader();
        reader.readAsDataURL(blob);
  
        reader.onload = () => {
          // Get the base64 data from FileReader result
          const base64Image = reader.result;
  
          // Update the state with the fetched image data
          this.setState(prevState => ({
            whiteLabling: {
              ...prevState.whiteLabling,
              [fieldName]: base64Image // Update the field corresponding to the fetched image
            }
          }));
  
          if (fieldName === 'favIcon') {
            let domElements = document.getElementById('favicon');
            if (base64Image && domElements) {
              domElements.setAttribute('href', base64Image);
            }
          }
        };
      } else {
        console.error('Failed to fetch image:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error in getImage:', error);
    }
  };
  
  
  // getImage = async (id, fieldName) => {
  //   try {
  //     const response = await Axios.get(`/api/media/public/${id}`, {
  //       responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
  //     });
  //     const filename = id;
  //     const parts = filename.split('.');
  //     const extension = parts[parts.length - 1];
  
  //     if (response.status === 200) {
  //       // Convert the array buffer to base64
  //       const base64Image = Buffer.from(response.data, 'binary').toString('base64');
  //       const imageData = `data:image/${extension};base64,${base64Image}`;
        
  //       // Update the state with the fetched image data
  //       this.setState(prevState => ({
  //         whiteLabling: {
  //           ...prevState.whiteLabling,
  //           [fieldName]: imageData // Update the field corresponding to the fetched image
  //         }
  //       }));
  //       if(fieldName === 'favIcon'){
  //         let domElements = document.getElementById('favicon');
  //           if (imageData && imageData && domElements) {
  //             domElements.setAttribute('href', imageData);
  //           }
  //       }
  //     } else {
  //       console.error('Failed to fetch image:', response.status, response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error in getImage:', error);
  //   }
  // };
  checkWebsite = () => {

    this.setState({ loading: true, callResponse: false }, () => {
 
      instance({
        url: `/api/accounts/labeling`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params:{
          hostUrl: window.location.host
        }
      })
 
          .then(res => {
                this.getImage(res?.favIcon, 'favIcon');
                this.getImage(res?.logo, 'logo');
                this.getImage(res?.logInPageImage, 'logInPageImage');
              this.setState({
                whiteLabling: {...res},
                callResponse: true,
                loading: false,
                serverinfo:{attributes:{...res}}
              },()=>{
                      this.props.dispatch(ServerInfo(this.state.serverinfo))
              })
              document.title = res.serviceTitle
              let domElements = document.getElementById('favicon')
              if (res && res.favIcon && domElements) {
                domElements.setAttribute('href', res.favIcon)
              }
        //   })
        // } else {
        //   this.setState({
        //     isDown: true
        //   })
        //   console.log('Server is Down')
        //     throw response
        //   }
        }).catch(error => {
          console.warn("err=====", error);
          this.setState({
            loading: false,
            isDown: false,
            callResponse: true,
          })
         })       
    })
    
  }
 
  setCookie (name, value, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie =
      name + '= ' + encodeURIComponent(value) + ';' + expires
  }

  componentWillMount () {
    this.checkWebsite();
  }

  saveIp = async () => {
    const response = await Axios.get('https://api.ipify.org/?format=json');
    if(response.status === 200 && response.data && response.data.ip) {
      this.setCookie('UIP', response.data.ip, 1);
    }
  }

  myref = e => {
    if(e) {
      this.setState({
        mainClass: 'scroll-width-'+(e.offsetWidth - e.scrollWidth)
      })
    }
  }

  render () {
 
      if (this.state.callResponse) {
        return (
          <div className={'fms '+this.state.mainClass} >
            {/* <Login {...this.state} {...this.props}/> */}
            <Routes {...this.state} {...this.props} />
            <div
              id='scrollable-div'
              style={{overflow: 'scroll', opacity: 0, position: 'absolute', left: '-200%', top: '-200%'}}
              ref={this.myref}
            ></div>
          </div>
        )
      } 
      if(this.state.loading) {
        return <Loader />
      } else {
        if (this.state.isDown) {
          return (
            <h2 style={{ textAlign: 'center' }}>
              Oops Something Went Wrong Try Again
            </h2>
          )
        } else {
          return null
        }
      }
    }
}
export default connect()(withLocalize(App))