import React from 'react'
// import { withLocalize } from 'react-localize-redux'
import Style from 'style-it'
// import Loader from '../../Loader'
import DeliveryNoteFilter from '../../../Components/common/DeliveryNoteFilter'
import { useSelector } from 'react-redux'
const DeliveryNoteSidebar=(props)=>{
  const geoFence=useSelector((state)=>state.geoFence)
  const themecolors=useSelector((state)=>state.themeColors)
  const submitSideBarSearch = (e)=>{
    console.error("data",e)
    props.fetchData('userId',1,50,false,true,e)
  }
    // const { workFlows } = this.props
    return (
      <Style>
        {`.material-icons {vertical-align: middle; color: inherit}`}
        <div className='fms-sidebar sidebar-bg-layer'>
        <div style={{ padding: 13 }}>
       <h3
          style={{ display: 'flex', alignItems: 'center', margin: 0 }}
        >
          <span>{props.translate('deliveryNote')}</span>
          </h3>
        </div>
        {
            <DeliveryNoteFilter
              {...props}
              geoFence={geoFence}
              themecolors={themecolors}
              formSubmit={submitSideBarSearch}
              sidebar
              invoice
              loader={props.loader}
            />
        }
        </div>
      </Style>
    )
}

export default DeliveryNoteSidebar

