import React, {  useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
// import { SnackbarProvider, } from 'notistack'
import isEqual from 'react-fast-compare'
import { addJob, removeJob, updateJob } from './../../Actions/Jobs'
import AddJobs from './addJob'
import { errorHandler, validEmailPattern, validNumberPattern } from './../../Helpers'
import moment from 'moment'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'

const jobDataFormat = (data, attributes) => ({
  id: data.id ? data.id : 0,
  attributes: { ...attributes },
  description: data.description,
  start: data.start,
  end: data.end,
  triggerTime: data.triggerTime,
  period: data.period,
  inProgress: data.inProgress,
  days: data.period && data.period !== 'once' ? data.days.toString() : '',
  type: data.type,
  status: data.status,
  lastExecutedLog: data.lastExecutedLog,
  lastExecuted: data.lastExecuted,
  includeTriggerDay:data.includeTriggerDay || false,
  // includeExtendedHours:data?.includeExtendedHours?data.includeExtendedHours:false,
  // includePartiallyInRange:data?.includePartiallyInRange||false
  
})

const itemGroupTypes = {
  device: 'Device',
  driver: 'Driver',
  vehicle: 'Vehicle',
}

const formDefault = {
  description: '',
  start: null,
  attributes: {
    controlDays: [1, 2, 3, 4, 5, 6, 7],
    // controlTimeStart: moment('00:00', 'HH:mm').toISOString(),
    // controlTimeEnd: moment('23:59', 'HH:mm').toISOString(),
    controlTimeStart: '00:00',
    // controlTimeEnd: moment('23:59', 'HH:mm').toISOString(),
    controlTimeEnd: '23:59',
    geoTimeThreshold: 1,
    withV1Merge:false,
    enableRawDataAnalysis:false,
    attachmentType:"excel"
  },
  end: null,
  triggerTime: moment('03:30', 'HH:mm').toISOString(),
  period: 'once',
  inProgress: false,
  days: '1',
  type: 'report',
  status: true,
  lastExecutedLog: '',
  lastExecuted: '',
  email: [{ id: 1, text: '' }],
  mailTo: 'user',
  includeTriggerDay:false,
  // includeExtendedHours:true,
  // includePartiallyInRange:false,
}
const defaultSteps ={
  steps: [{id: 1, title: 'reportBetweenTime'}, {id: 2, title: 'selectReportTemplate'}],
  activeStep: 0,
  removedIdPropcess: '',
  selectedJobDate: 'Never End',
  reportUnits: 'device',
  reportDuration: '',
  selectedJob: '',
  validatedForm: false,
  notificationIds: [],
  types: [],
  controlDays: [1, 2, 3, 4, 5, 6, 7],
  controlTime: [0, 1439],
  sensorIds: [],
  driverIds: [],
  geofenceIds: [],
  allowFields: [],
  sensorValues: [{input: "", index: "", value: ""}],
  eventType: 'notification',
  allSensors: false,
  isSensorTracing: false,
  from: null,
  to: null,
  selectedDate: '0',
  isVisableUserBtn: false,
  isVisableTrackerModal: false,
  jobId: '',
  deviceIds: [],
  groupIds: [],
  vehicleIds: [],
  reportType: '',
}
// class jobsModal extends Component {
const jobsModal = (props) => {
  const [state, setState] = useState({
      // report state
      form: { ...formDefault },
      ...defaultSteps,
      addOption: false,
      editOption: false,
      dm_isVisable: false,
      
    })

    const rangesFuture = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      'Next 7 Days': [
        moment().startOf('day'),
        moment().add(6, 'days').endOf('day'),
      ],
      'Next 30 Days': [
        moment().startOf('day'),
        moment().add(29, 'days').endOf('day'),
      ],
      'This Month': [
        moment().startOf('month').startOf('day'),
        moment().endOf('month').endOf('day'),
      ],
      'Next Month': [
        moment().add(1, 'month').startOf('month').startOf('day'),
        moment().add(1, 'month').endOf('month').endOf('day'),
      ],
      'Never End': [null, null],
    };
  
    // useEffect(() => {
    //   const initializeComponent = () => {
    //     const template =
    //       props.selecteditem.attributes && props.selecteditem.attributes.rpTmplId
    //         ? props.templates.find(
    //             (temp) => temp.rpTmpl.id === props.selecteditem.attributes.rpTmplId
    //           )
    //         : null;
  
    //     if (props.showItemDetail) {
    //       setState((prevState) => ({
    //         ...prevState,
    //         selectedJob: { ...props.selecteditem },
    //         jobId: props.selecteditem.id,
    //       }));
    //     } else {
    //       if (props.activeOperation === 'edit' && !state.editOption && template) {
    //         const allowFields = template.rpTmpl.attributes.allowFields || [];
    //         let currentSteps = state.steps.filter((step) => step.id < 3);
  
    //         if (allowFields.some((r) => ['eventType', 'notification'].includes(r))) {
    //           currentSteps.push({ id: 3, title: 'selectEventOrNotification' });
    //         } else if (allowFields.some((r) => ['geofence'].includes(r))) {
    //           currentSteps.push({ id: 4, title: 'selectGeofence' });
    //         } else if (allowFields.some((r) => ['sensor', 'digitalSensor'].includes(r))) {
    //           currentSteps.push({ id: 5, title: 'selectSensor' });
    //         }
  
    //         currentSteps.push({ id: 6, title: 'executionSchedule' });
  
    //         setState((prevState) => ({ ...prevState, steps: currentSteps }));
  
    //         const ids = Object.keys(rangesFuture);
    //         let selectedJobDate = 'custom';
    //         const format = 'YYYY-MM-DD HH:mm';
    //         ids.map((id) => {
    //           if (rangesFuture[id] && rangesFuture[id].length === 2) {
    //             if (
    //               props.selecteditem.start === null &&
    //               props.selecteditem.end === null
    //             ) {
    //               selectedJobDate = 'Never End';
    //             } else if (
    //               rangesFuture[id][0] &&
    //               rangesFuture[id][1] &&
    //               rangesFuture[id][0].format(format) ===
    //                 moment(props.selecteditem.start).format(format) &&
    //               rangesFuture[id][1].format(format) ===
    //                 moment(props.selecteditem.end).format(format)
    //             ) {
    //               selectedJobDate = id;
    //             }
    //           }
    //           return null;
    //         });
  
    //         const {
    //           reportDuration,
    //           mailTo,
    //           emails,
    //           controlTimeStart,
    //           controlTimeEnd,
    //           sensors,
    //           notificationIds,
    //           deviceIds,
    //           driverIds,
    //           types,
    //           itemGroupIds: groupIds = [],
    //           vehicleIds,
    //           geofenceIds,
    //           sensorValues,
    //           rpTmplId,
    //         } = props.selecteditem.attributes;
  
    //         checkReportData(reportDuration);
  
    //         setState((prevState) => ({
    //           ...prevState,
    //           jobId: template && props.selecteditem.id,
    //           selectedJob: { ...props.selecteditem },
    //           editOption: true,
    //           addOption: false,
    //           dm_isVisable: true,
    //           isVisableUserBtn: false,
    //           form: {
    //             ...props.selecteditem,
    //             mailTo: mailTo || 'user',
    //             days: props.selecteditem.days && props.selecteditem.days.split(','),
    //             email: emails ? spiltItem(emails) : [{ id: 1, text: '' }],
    //           },
    //           reportUnits:
    //             deviceIds && deviceIds.length
    //               ? 'device'
    //               : driverIds && driverIds.length
    //               ? 'driver'
    //               : groupIds && groupIds.length
    //               ? 'device'
    //               : vehicleIds && vehicleIds.length
    //               ? 'vehicle'
    //               : 'device',
    //           selectedJobDate,
    //           deviceIds: deviceIds || [],
    //           groupIds: groupIds || [],
    //           groupView: groupIds.length ? 2 : 1,
    //           vehicleIds: vehicleIds || [],
    //           driverIds: driverIds || [],
    //           geofenceIds: geofenceIds || [],
    //           sensorIds: sensors || [],
    //           sensorValues: sensorValues || [],
    //           types: types || [],
    //           notificationIds: notificationIds || [],
    //           allSensors:
    //             sensorValues &&
    //             sensorValues.length === 1 &&
    //             sensorValues[0] &&
    //             sensorValues[0].value === 'allSensors',
    //           reportType: rpTmplId || '',
    //           allowFields,
    //           eventType: allowFields.includes(state.eventType)
    //             ? state.eventType
    //             : allowFields.includes('notification')
    //             ? 'notification'
    //             : allowFields.includes('eventType')
    //             ? 'eventType'
    //             : '',
    //           controlTime: [
    //             moment.duration(controlTimeStart || '00:00').asMinutes(),
    //             moment.duration(controlTimeEnd || '23:59').asMinutes(),
    //           ],
    //         }));
  
    //         checkReportRequiredFields();
    //       }
    //       if (
    //         props.activeOperation === 'delete' &&
    //         state.removedIdProcess !== props.selecteditem.id
    //       ) {
    //         setState((prevState) => ({
    //           ...prevState,
    //           removedIdProcess: props.selecteditem.id,
    //         }));
    //         onDeleteJob(props.selecteditem);
    //       }
    //       if (props.activeOperation === 'add' && !state.addOption) {
    //         setState((prevState) => ({
    //           ...prevState,
    //           form: { ...formDefault },
    //           ...defaultSteps,
    //           addOption: true,
    //           jobId: 0,
    //           editOption: false,
    //           dm_isVisable: true,
    //         }));
    //         checkReportRequiredFields();
    //       }
    //     }
    //     setState((prevState) => ({
    //       ...prevState,
    //       selectedJobDate: props.selecteditem?.attributes?.selectedJobDate || '',
    //     }));
    //   };
  
    //   initializeComponent();
    // }, [props.selecteditem, props.templates, props.showItemDetail, props.activeOperation]);
  
    
    // componentWillMount () {
    //   const rangesFuture = {
    //     Today: [moment().startOf('day'), moment().endOf('day')],
    //     'Next 7 Days': [
    //       moment().startOf('day'),
    //       moment()
    //         .add(6, 'days')
    //         .endOf('day')
    //     ],
    //     'Next 30 Days': [
    //       moment().startOf('day'),
    //       moment()
    //         .add(29, 'days')
    //         .endOf('day')
    //     ],
    //     'This Month': [
    //       moment()
    //         .startOf('month')
    //         .startOf('day'),
    //       moment()
    //         .endOf('month')
    //         .endOf('day')
    //     ],
    //     'Next Month': [
    //       moment()
    //         .add(1, 'month')
    //         .startOf('month')
    //         .startOf('day'),
    //       moment()
    //         .add(1, 'month')
    //         .endOf('month')
    //         .endOf('day')
    //     ],
    //     'Never End': [null, null]
    //   }
    //   const template =
    //     props.selecteditem.attributes &&
    //     props.selecteditem.attributes.rpTmplId
    //       ? props.templates.find(
    //           temp =>
    //             temp.rpTmpl.id === props.selecteditem.attributes.rpTmplId
    //         )
    //       : null
    //   // fetch group list
    //   if (props.showItemDetail) {
    //     setState((prevState) => ({ ...prevState, 
    //       selectedJob: { ...props.selecteditem },
    //       jobId: props.selecteditem.id
    //     }))
    //   } else {
    //     if (
    //       props.activeOperation === 'edit' &&
    //       !state.editOption &&
    //       template
    //     ) {
    //       const allowFields =
    //         template &&
    //         template.rpTmpl &&
    //         template.rpTmpl.attributes &&
    //         template.rpTmpl.attributes.allowFields
    //           ? template.rpTmpl.attributes.allowFields
    //           : []
  
  
  
    //       let currentSteps = state.steps.filter(step => step.id < 3)
  
    //       if(allowFields.some(r=> ["eventType", "notification"].includes(r))) {
    //         // add notification step
    //         currentSteps.push({id: 3, title: "selectEventOrNotification"})
    //       }
    //       else if(allowFields.some(r=> ["geofence"].includes(r))) {
    //         // add notification step
    //         currentSteps.push({id: 4, title: "selectGeofence"})
    //       }
    //       else if(allowFields.some(r=> ["sensor", "digitalSensor"].includes(r))) {
    //         // add notification step
    //         currentSteps.push({id: 5, title: "selectSensor"})
    //       }
          
    //       currentSteps.push({id: 6, title: "executionSchedule"})
          
   
    //       setState((prevState) => ({ ...prevState, steps: currentSteps }))
          
  
    //       const ids = Object.keys(rangesFuture)
    //       let selectedJobDate = 'custom'
    //       const format = 'YYYY-MM-DD HH:mm'
    //       ids.map(id => {
    //         if (
    //           rangesFuture &&
    //           rangesFuture[id] &&
    //           rangesFuture[id].length === 2
    //         ) {
    //           if (
    //             props.selecteditem &&
    //             props.selecteditem.start === null &&
    //             props.selecteditem.end === null
    //           ) {
    //             selectedJobDate = 'Never End'
    //           } else if (
    //             rangesFuture[id][0] &&
    //             rangesFuture[id][1] &&
    //             rangesFuture[id][0].format(format) ===
    //               moment(props.selecteditem.start).format(format) &&
    //             rangesFuture[id][1].format(format) ===
    //               moment(props.selecteditem.end).format(format)
    //           ) {
    //             selectedJobDate = id
    //           }
    //         }
    //         return null
    //       })
  
    //       const {reportDuration, mailTo, emails, controlTimeStart, controlTimeEnd, sensors, notificationIds, deviceIds, driverIds, types, itemGroupIds: groupIds = [], vehicleIds, geofenceIds, sensorValues, rpTmplId} = props.selecteditem.attributes
    //       checkReportData(reportDuration)
    //       setState((prevState) => ({ ...prevState, 
    //           jobId: template && props.selecteditem.id,
    //           selectedJob: { ...props.selecteditem },
    //           editOption: true,
    //           addOption: false,
    //           dm_isVisable: true,
    //           isVisableUserBtn: false,
    //           form: {
    //             ...props.selecteditem,
    //             mailTo: mailTo || 'user',
    //             days: props.selecteditem.days && props.selecteditem.days.split(','),
    //             email: emails
    //               ? spiltItem(emails)
    //               : [{ id: 1, text: '' }]
    //           },
    //           reportUnits:
    //             deviceIds &&
    //             deviceIds.length
    //               ? 'device'
    //               : driverIds &&
    //                 driverIds.length
    //               ? 'driver'
    //               : groupIds &&
    //                 groupIds.length
    //               ? 'device'
    //               : vehicleIds &&
    //                 vehicleIds.length
    //               ? 'vehicle'
    //               : 'device',
    //           selectedJobDate,
    //           deviceIds: deviceIds || [],
    //           groupIds: groupIds || [],
    //           groupView: groupIds.length ? 2 : 1,
    //           vehicleIds: vehicleIds || [],
    //           driverIds: driverIds || [],
    //           geofenceIds: geofenceIds || [],
    //           sensorIds: sensors || [],
    //           sensorValues: sensorValues || [],
    //           types: types || [],
    //           notificationIds: notificationIds || [],
    //           allSensors: sensorValues && sensorValues.length === 1 && sensorValues[0] && sensorValues[0].value === 'allSensors',
    //           reportType: rpTmplId || '',
    //           allowFields,
    //           eventType: allowFields.includes(state.eventType)
    //             ? state.eventType
    //             : allowFields.includes('notification')
    //             ? 'notification'
    //             : allowFields.includes('eventType')
    //             ? 'eventType'
    //             : '',
    //           controlTime: [
    //             moment
    //               .duration(
    //                 controlTimeStart || '00:00'
    //               )
    //               .asMinutes(),
    //             moment
    //               .duration(
    //                 controlTimeEnd || '23:59'
    //               )
    //               .asMinutes()
    //           ]
    //         },
    //         () => {
    //           checkReportRequiredFields()
    //         }
    //       ))
    //     }
    //     if (
    //       props.activeOperation === 'delete' &&
    //       state.removedIdPropcess !== props.selecteditem.id
    //     ) {
    //       setState((prevState) => ({ ...prevState, 
    //         removedIdPropcess: props.selecteditem.id
    //       }))
    //       onDeleteJob(props.selecteditem)
    //     }
    //     if (props.activeOperation === 'add' && !state.addOption) {
    //       setState((prevState) => ({ ...prevState, 
    //           form: { ...formDefault },
    //           ...defaultSteps,
    //           addOption: true,
    //           jobId: 0,
    //           editOption: false,
    //           dm_isVisable: true,
    //         },
    //         () => checkReportRequiredFields()
    //       ))
    //     }
    //   }
    //   setState((prevState) => ({ ...prevState, 
    //     selectedJobDate:props.selecteditem?.attributes?.selectedJobDate || ""
    //   }))
    // }
  
    // componentWillReceiveProps (nextprops) {
    //   const rangesFuture = {
    //     Today: [moment().startOf('day'), moment().endOf('day')],
    //     'Next 7 Days': [
    //       moment().startOf('day'),
    //       moment()
    //         .add(6, 'days')
    //         .endOf('day')
    //     ],
    //     'Next 30 Days': [
    //       moment().startOf('day'),
    //       moment()
    //         .add(29, 'days')
    //         .endOf('day')
    //     ],
    //     'This Month': [
    //       moment()
    //         .startOf('month')
    //         .startOf('day'),
    //       moment()
    //         .endOf('month')
    //         .endOf('day')
    //     ],
    //     'Next Month': [
    //       moment()
    //         .add(1, 'month')
    //         .startOf('month')
    //         .startOf('day'),
    //       moment()
    //         .add(1, 'month')
    //         .endOf('month')
    //         .endOf('day')
    //     ],
    //     'Never End': [null, null]
    //   }
  
    //   const template = nextprops.templates.find(
    //     temp =>
    //       nextprops.selecteditem.attributes &&
    //       temp.rpTmpl.id === nextprops.selecteditem.attributes.rpTmplId
    //   )
    //   if (nextprops.selecteditem.id !== state.jobId ) { 
    //     if (nextprops.showItemDetail) {
    //       setState((prevState) => ({ ...prevState, 
    //         selectedJob: { ...nextprops.selecteditem },
    //         jobId: nextprops.selecteditem.id
    //       }))
    //     } else {
    //       if (nextprops.activeOperation === 'edit' && !state.editOption && template) {
    //         const ids = Object.keys(rangesFuture)
    //         const allowFields =
    //           template &&
    //           template.rpTmpl &&
    //           template.rpTmpl.attributes &&
    //           template.rpTmpl.attributes.allowFields
    //             ? template.rpTmpl.attributes.allowFields
    //             : []
  
    //             let currentSteps = state.steps.filter(step => step.id < 3)
  
    //             if(allowFields.some(r=> ["eventType", "notification"].includes(r))) {
    //               // add notification step
    //               currentSteps.push({id: 3, title: "selectEventOrNotification"})
    //             }
    //             else if(allowFields.some(r=> ["geofence"].includes(r))) {
    //               // add notification step
    //               currentSteps.push({id: 4, title: "selectGeofence"})
    //             }
    //             else if(allowFields.some(r=> ["sensor", "digitalSensor"].includes(r))) {
    //               // add notification step
    //               currentSteps.push({id: 5, title: "selectSensor"})
    //             }
                
    //             currentSteps.push({id: 6, title: "executionSchedule"})
                
        
    //             setState((prevState) => ({ ...prevState,  steps: currentSteps }))
  
    //         let selectedJobDate = 'custom'
    //         const format = 'YYYY-MM-DD HH:mm'
    //         ids.map(id => {
    //           if (
    //             rangesFuture &&
    //             rangesFuture[id] &&
    //             rangesFuture[id].length === 2
    //           ) {
    //             if (
    //               nextprops.selecteditem.start === null &&
    //               nextprops.selecteditem.end === null
    //             ) {
    //               selectedJobDate = 'Never End'
    //             } else if (
    //               rangesFuture[id][0] &&
    //               rangesFuture[id][1] &&
    //               rangesFuture[id][0].format(format) ===
    //                 moment(nextprops.selecteditem.start).format(format) &&
    //               rangesFuture[id][1].format(format) ===
    //                 moment(nextprops.selecteditem.end).format(format)
    //             ) {
    //               selectedJobDate = id
    //             }
    //           }
    //           return null
    //         })
  
    //         const {reportDuration, mailTo, emails, controlTimeStart, controlTimeEnd, sensors, notificationIds, deviceIds, driverIds, types, itemGroupIds: groupIds = [], vehicleIds, geofenceIds, sensorValues, rpTmplId} = nextprops.selecteditem.attributes
  
    //         checkReportData(reportDuration)
    //         setState((prevState) => ({ ...prevState, 
    //             jobId: nextprops.selecteditem.id,
    //             selectedJob: { ...nextprops.selecteditem },
    //             editOption: true,
    //             addOption: false,
    //             dm_isVisable: true,
    //             isVisableUserBtn: false,
  
    //             reportUnits:
    //               deviceIds &&
    //               deviceIds.length
    //                 ? 'device'
    //                 : driverIds &&
    //                   driverIds.length
    //                 ? 'driver'
    //                 : groupIds &&
    //                   groupIds.length
    //                 ? 'device'
    //                 : vehicleIds &&
    //                   vehicleIds.length
    //                 ? 'vehicle'
    //                 : 'device',
    //             form: {
    //               ...nextprops.selecteditem,
    //               mailTo: mailTo || 'user',
    //               days: props.selecteditem.days && props.selecteditem.days.split(','),
    //               email: emails
    //                 ? spiltItem(emails)
    //                 : [{ id: 1, text: '' }]
    //             },
    //             selectedJobDate,
    //             deviceIds: deviceIds || [],
    //             groupIds: groupIds || [],
    //             groupView: groupIds.length ? 2 : 1,
    //             vehicleIds: vehicleIds || [],
    //             driverIds: driverIds || [],
    //             geofenceIds: geofenceIds || [],
    //             sensorIds: sensors || [],
    //             sensorValues: sensorValues || [],
    //             allSensors: sensorValues && sensorValues.length === 1 && sensorValues[0] && sensorValues[0].value === 'allSensors',
    //             reportType: rpTmplId || '',
    //             types: types || [],
    //             notificationIds: notificationIds || [],
    //             allowFields,
    //             eventType: allowFields.includes(state.eventType)
    //               ? state.eventType
    //               : allowFields.includes('notification')
    //               ? 'notification'
    //               : allowFields.includes('eventType')
    //               ? 'eventType'
    //               : '',
    //             controlTime: [
    //               moment
    //                 .duration(
    //                  controlTimeStart ||
    //                     '00:00'
    //                 )
    //                 .asMinutes(),
    //               moment
    //                 .duration(
    //                   controlTimeEnd || '23:59'
    //                 )
    //                 .asMinutes()
    //             ]
    //           },
    //           () => checkReportRequiredFields()
    //         ))
    //       }
    //       if (nextprops.activeOperation === 'add' && !state.addOption) {
    //         setState((prevState) => ({ ...prevState, 
    //             form: { ...formDefault },
    //           ...defaultSteps,
    //           addOption: true,
    //           jobId: 0,
    //           editOption: false,
    //           dm_isVisable: true,
    //           },
    //           () => checkReportRequiredFields()
    //         ))
    //       }
    //     }
    //   }
    //   if (
    //     nextprops.activeOperation === 'delete' &&
    //     state.removedIdPropcess !== nextprops.selecteditem.id
    //   ) {
    //     setState((prevState) => ({ ...prevState, 
    //       removedIdPropcess: nextprops.selecteditem.id
    //     }))
    //     onDeleteJob(nextprops.selecteditem)
    //   }
    // }



    useEffect(() => {
      const template = props.templates.find(
        temp => 
          props.selecteditem.attributes && 
        temp.rpTmpl.id === props.selecteditem.attributes.rpTmplId
      );
  
      if (props.showItemDetail) {
        setState((prevState) => ({
          ...prevState,
          selectedJob: { ...props.selecteditem },
          jobId: props.selecteditem.id,
        }));
      } else {
        if (props.activeOperation === 'edit' && !state.editOption && template) {
          const allowFields = template?.rpTmpl?.attributes?.allowFields || [];
          let currentSteps = state.steps.filter(step => step.id < 3);
  
          if (allowFields.some(r => ["eventType", "notification"].includes(r))) {
            currentSteps.push({ id: 3, title: "selectEventOrNotification" });
          } else if (allowFields.some(r => ["geofence"].includes(r))) {
            currentSteps.push({ id: 4, title: "selectGeofence" });
          } else if (allowFields.some(r => ["sensor", "digitalSensor"].includes(r))) {
            currentSteps.push({ id: 5, title: "selectSensor" });
          }
  
          currentSteps.push({ id: 6, title: "executionSchedule" });
  
          setState((prevState) => ({ ...prevState, steps: currentSteps }));
  
          const ids = Object.keys(rangesFuture);
          let selectedJobDate = 'custom';
          const format = 'YYYY-MM-DD HH:mm';
  
          ids.forEach(id => {
            if (rangesFuture[id] && rangesFuture[id].length === 2) {
              if (props.selecteditem.start === null && props.selecteditem.end === null) {
                selectedJobDate = 'Never End';
              } else if (
                rangesFuture[id][0]?.format(format) === moment(props.selecteditem.start).format(format) &&
                rangesFuture[id][1]?.format(format) === moment(props.selecteditem.end).format(format)
              ) {
                selectedJobDate = id;
              }
            }
          });
  
          const {
            reportDuration, mailTo, emails, controlTimeStart, controlTimeEnd,
            sensors,  deviceIds, driverIds, 
            // sensors, notificationIds, deviceIds, driverIds, types,
            itemGroupIds: groupIds = [], vehicleIds, geofenceIds, sensorValues, rpTmplId
          } = props.selecteditem.attributes;
  
          checkReportData(reportDuration);
  
          setState((prevState) => ({
            ...prevState,
            jobId: props.selecteditem.id,
            selectedJob: { ...props.selecteditem },
            editOption: true,
            addOption: false,
            dm_isVisable: true,
            isVisableUserBtn: false,
            form: {
              ...props.selecteditem,
              mailTo: mailTo || 'user',
              days: props.selecteditem.days && props.selecteditem.days.split(','),
              email: emails ? spiltItem(emails) : [{ id: 1, text: '' }]
            },
            reportUnits:
              deviceIds.length ? 'device' :
              driverIds.length ? 'driver' :
              groupIds.length ? 'device' :
              vehicleIds.length ? 'vehicle' : 'device',
            selectedJobDate,
            deviceIds: deviceIds || [],
            groupIds: groupIds || [],
            groupView: groupIds.length ? 2 : 1,
            vehicleIds: vehicleIds || [],
            driverIds: driverIds || [],
            geofenceIds: geofenceIds || [],
            sensorIds: sensors || [],
            sensorValues: sensorValues || [],
            allSensors: sensorValues.length === 1 && sensorValues[0]?.value === 'allSensors',
            reportType: rpTmplId || '',
            allowFields,
            eventType: allowFields.includes(state.eventType)
              ? state.eventType
              : allowFields.includes('notification')
                ? 'notification'
                : allowFields.includes('eventType')
                  ? 'eventType'
                  : '',
            controlTime: [
              moment.duration(controlTimeStart || '00:00').asMinutes(),
              moment.duration(controlTimeEnd || '23:59').asMinutes()
            ]
          }));
        }
  
        if (props.activeOperation === 'delete' && state.removedIdPropcess !== props.selecteditem.id) {
          setState((prevState) => ({
            ...prevState,
            removedIdPropcess: props.selecteditem.id,
          }));
          onDeleteJob(props.selecteditem);
        }
  
        if (props.activeOperation === 'add' && !state.addOption) {
          setState((prevState) => ({
            ...prevState,
            form: { ...formDefault },
            ...defaultSteps,
            addOption: true,
            jobId: 0,
            editOption: false,
            dm_isVisable: true,
          }), checkReportRequiredFields);
        }
      }
  
      setState((prevState) => ({
        ...prevState,
        selectedJobDate: props.selecteditem?.attributes?.selectedJobDate || '',
      }));
    }, [props]);
  
    useEffect(() => {
      // Your code from componentWillMount
      const rangesFuture = {
        Today: [moment().startOf('day'), moment().endOf('day')],
        'Next 7 Days': [moment().startOf('day'), moment().add(6, 'days').endOf('day')],
        'Next 30 Days': [moment().startOf('day'), moment().add(29, 'days').endOf('day')],
        'This Month': [moment().startOf('month').startOf('day'), moment().endOf('month').endOf('day')],
        'Next Month': [moment().add(1, 'month').startOf('month').startOf('day'), moment().add(1, 'month').endOf('month').endOf('day')],
        'Never End': [null, null],
      };
  
      const template = props.selecteditem.attributes && props.selecteditem.attributes.rpTmplId
        ? props.templates.find(temp => temp.rpTmpl.id === props.selecteditem.attributes.rpTmplId)
        : null;
  
      if (props.showItemDetail) {
        setState((prevState) => ({
          ...prevState,
          selectedJob: { ...props.selecteditem },
          jobId: props.selecteditem.id,
        }));
      } else {
        if (props.activeOperation === 'edit' && !state.editOption && template) {
          const allowFields = template?.rpTmpl?.attributes?.allowFields || [];
          let currentSteps = state.steps.filter(step => step.id < 3);
  
          if (allowFields.some(r => ["eventType", "notification"].includes(r))) {
            currentSteps.push({ id: 3, title: "selectEventOrNotification" });
          } else if (allowFields.some(r => ["geofence"].includes(r))) {
            currentSteps.push({ id: 4, title: "selectGeofence" });
          } else if (allowFields.some(r => ["sensor", "digitalSensor"].includes(r))) {
            currentSteps.push({ id: 5, title: "selectSensor" });
          }
  
          currentSteps.push({ id: 6, title: "executionSchedule" });
  
          setState((prevState) => ({ ...prevState, steps: currentSteps }));
  
          const ids = Object.keys(rangesFuture);
          let selectedJobDate = 'custom';
          const format = 'YYYY-MM-DD HH:mm';
  
          ids.forEach(id => {
            if (rangesFuture[id] && rangesFuture[id].length === 2) {
              if (props.selecteditem.start === null && props.selecteditem.end === null) {
                selectedJobDate = 'Never End';
              } else if (
                rangesFuture[id][0]?.format(format) === moment(props.selecteditem.start).format(format) &&
                rangesFuture[id][1]?.format(format) === moment(props.selecteditem.end).format(format)
              ) {
                selectedJobDate = id;
              }
            }
          });
  
          const {
            reportDuration, mailTo, emails, controlTimeStart, controlTimeEnd,
            sensors,  deviceIds, driverIds, 
            // sensors, notificationIds, deviceIds, driverIds, types,
            itemGroupIds: groupIds = [], vehicleIds, geofenceIds, sensorValues, rpTmplId
          } = props.selecteditem.attributes;
  
          checkReportData(reportDuration);
  
          setState((prevState) => ({
            ...prevState,
            jobId: props.selecteditem.id,
            selectedJob: { ...props.selecteditem },
            editOption: true,
            addOption: false,
            dm_isVisable: true,
            isVisableUserBtn: false,
            form: {
              ...props.selecteditem,
              mailTo: mailTo || 'user',
              days: props.selecteditem.days && props.selecteditem.days.split(','),
              email: emails ? spiltItem(emails) : [{ id: 1, text: '' }]
            },
            reportUnits:
              deviceIds.length ? 'device' :
              driverIds.length ? 'driver' :
              groupIds.length ? 'device' :
              vehicleIds.length ? 'vehicle' : 'device',
            selectedJobDate,
            deviceIds: deviceIds || [],
            groupIds: groupIds || [],
            groupView: groupIds.length ? 2 : 1,
            vehicleIds: vehicleIds || [],
            driverIds: driverIds || [],
            geofenceIds: geofenceIds || [],
            sensorIds: sensors || [],
            sensorValues: sensorValues || [],
            allSensors: sensorValues.length === 1 && sensorValues[0]?.value === 'allSensors',
            reportType: rpTmplId || '',
            allowFields,
            eventType: allowFields.includes(state.eventType)
              ? state.eventType
              : allowFields.includes('notification')
                ? 'notification'
                : allowFields.includes('eventType')
                  ? 'eventType'
                  : '',
            controlTime: [
              moment.duration(controlTimeStart || '00:00').asMinutes(),
              moment.duration(controlTimeEnd || '23:59').asMinutes()
            ]
          }), checkReportRequiredFields);
        }
  
        if (props.activeOperation === 'delete' && state.removedIdPropcess !== props.selecteditem.id) {
          setState((prevState) => ({
            ...prevState,
            removedIdPropcess: props.selecteditem.id,
          }));
          onDeleteJob(props.selecteditem);
        }
  
        if (props.activeOperation === 'add' && !state.addOption) {
          setState((prevState) => ({
            ...prevState,
            form: { ...formDefault },
            ...defaultSteps,
            addOption: true,
            jobId: 0,
            editOption: false,
            dm_isVisable: true,
          }), checkReportRequiredFields);
        }
      }
  
      setState((prevState) => ({
        ...prevState,
        selectedJobDate: props.selecteditem?.attributes?.selectedJobDate || '',
      }));
    }, []);

    
    useEffect(() => {
      if(state.eventType){
      selectedNotifications([])
      selectedEvents([])
      }else if(state.form || state.reportDuration || 
              state.selectedDate || state.validatedForm ||
              state.deviceIds || state.groupIds ||
              state.driverIds || state.vehicleIds 
              
              )
              {
        if(state.form.description || state.reportDuration || 
          state.selectedDate || state.deviceIds || 
          state.groupIds || state.driverIds || state.vehicleIds  ){
          checkReportRequiredFields()
        }else{
          checkRequiredFields()
        }
      }

}, [state.eventType, state.form, state.reportDuration ,
    state.selectedDate, state.validatedForm ])

  const handleChangeType = eventType => {
    setState((prevState) => ({ ...prevState, 
        eventType
      } ))
  }

  const selectedNotifications = types => {
    setState((prevState) => ({ ...prevState, types }))
  }

  const selectedEvents = notificationIds => {
  
    setState((prevState) => ({ ...prevState,  notificationIds }))
  }

  // const onCloseModal = () => {
  //   setState((prevState) => ({ ...prevState, 
  //     isVisableTrackerModal: false,
  //     enableImagePopup: false
  //   }))
  // }

  const handleSelectedJobDate = selectedJobDate => {
    setState((prevState) => ({ ...prevState,  selectedJobDate }))
  }

  const checkReportData = data => {
    if (data) {
      if (
        data === 1 ||
        data === 7 ||
        data === 14 ||
        data === 30 ||
        data === 60 ||
        data === 90
      ) {
        setState((prevState) => ({ ...prevState, 
          selectedDate: data.toString(),
          reportDuration: ''
        }))
      } else {
        setState((prevState) => ({ ...prevState, 
          selectedDate: 'custom',
          reportDuration: data.toString()
        }))
      }
    }
  }

 const  ad_submit = () =>{
    let isTrue = true;
    if(state.isSensorTracing){
      if( (state.selectedDate === 'custom'? (state.reportDuration === 1? true : false) : state.selectedDate <=1)){
       isTrue = true
      }else{
        isTrue = false
      }
    }
    if(isTrue){
    let attributes = createReport()
    let {
      form,
      selectedJobDate,
      // controlTime
    } = state
    let { mailTo, email } = form
    if (mailTo !== 'user') {
      if (email[0].text) {
        let emailsarray = []
        email.map(em => {
          if (em.text) {
            emailsarray.push(em.text)
          }
          return null
        })
        let emails = emailsarray.join(',')
        attributes = {
          ...attributes,
          mailTo,
          emails,
          selectedJobDate,
          controlDays: form.attributes.controlDays,
          controlTimeStart:form.attributes.controlTimeStart,
          controlTimeEnd : form.attributes.controlTimeEnd,
        }
      }
    } else {
      attributes = {
        ...attributes,
        mailTo,
        selectedJobDate,
        controlDays: form.attributes.controlDays,
        controlTimeStart:form.attributes.controlTimeStart,
        controlTimeEnd : form.attributes.controlTimeEnd,
      }
    }
     const obj = jobDataFormat(form, attributes)

    instance({
      url: `/api/jobs`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data :{
        ...obj
      }
    })
      .then(job => {
              props.dispatch(addJob(job))
              props.onCloseModal()
              setState((prevState) => ({ ...prevState, 
                dm_isVisable: !state.dm_isVisable,
                addOption: false,
                selectedJob: '',
                form: formDefault,
                imagesData: ''
              }))
              props.dispatch(
                Notifications.success({
                  message: props.translate('scheduleCreated'),
                  autoDismiss: 10
                })
              )
      })
      .catch(error => {errorHandler(error, props.dispatch)
      })
    }else{
      props.dispatch(
        Notifications.error({
          message: props.translate('sensorTracingLimitError'),
          autoDismiss: 10
        })
      )
      setState((prevState) => ({ 
        ...prevState, 
        activeStep: 0
      }))
      validatedCurrentStep(1, '', -1)
    }
  }

  const ud_submit  =()=> {
    let isTrue = true;
    if(state.isSensorTracing){
      if( (state.selectedDate === 'custom'? (state.reportDuration === 1? true : false) : state.selectedDate <=1)){
       isTrue = true
      }else{
        isTrue = false
      }
    }
    if(isTrue){
    let attributes = createReport()
    let {
      form,
      selectedJobDate,
      // controlTime
    } = state

    let { mailTo, email } = form

    let timezone = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    if (mailTo !== 'user') {
      if (email[0].text) {
        let emailsarray = []
        email.map(em => {
          if (em.text) {
            emailsarray.push(em.text)
          }
          return null
        })
        let emails = emailsarray.join(',')
        attributes = {
          ...attributes,
          mailTo,
          emails,
          selectedJobDate,
          controlDays: form.attributes.controlDays,
          controlTimeStart:form.attributes.controlTimeStart,
          controlTimeEnd : form.attributes.controlTimeEnd,
          // controlTimeStart: moment()
          //   .startOf('day')
          //   .add(controlTime[0], 'minutes')
          //   .format('HH:mm'),
          // controlTimeEnd: moment()
          //   .startOf('day')
          //   .add(controlTime[1], 'minutes')
          //   .format('HH:mm')
        }
      }
    } else {
      attributes = {
        ...attributes,
        mailTo,
        selectedJobDate,
        controlDays: form.attributes.controlDays,
        controlTimeStart:form.attributes.controlTimeStart,
        controlTimeEnd : form.attributes.controlTimeEnd,
        // controlTimeStart: moment()
        //   .startOf('day')
        //   .add(controlTime[0], 'minutes')
        //   .format('HH:mm'),
        // controlTimeEnd: moment()
        //   .startOf('day')
        //   .add(controlTime[1], 'minutes')
        //   .format('HH:mm')
      }
    }
    const obj = jobDataFormat(form, attributes)
    // fetch(`/api/jobs/${state.jobId}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/jobs/${state.jobId}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data :{
        ...obj
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(job => {
            // if (job.status === 'success') {
              props.dispatch(updateJob(job))
              props.onCloseModal()
              props.dispatch(
                Notifications.success({
                  message: props.translate('scheduleUpdated'),
                  autoDismiss: 10
                })
              )
              setState((prevState) => ({ ...prevState, 
                dm_isVisable: !state.dm_isVisable,
                selectedJob: job,
                attributeChangesMessage: '',
                isVisableUserBtn: false,
                imagesData: ''
              }))
            // } else {
            //   props.dispatch(
            //     Notifications.error({
            //       message: job.message,
            //       autoDismiss: 10
            //     })
            //   )
            // }
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, props.dispatch)
        // if (e && e.text) {
        //   e.text().then(err => {
        //     if (err.includes('Duplicate entry')) {
        //       //props.enqueueSnackbar(props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        //       props.dispatch(
        //         Notifications.error({
        //           message: props.translate('uniqueIdIsAllreadyInUsed'),
        //           autoDismiss: 10
        //         })
        //       )
        //     } else {
        //       props.dispatch(
        //         Notifications.error({
        //           message: props.translate('somthingWentWrongMessage'),
        //           autoDismiss: 10
        //         })
        //       )
        //     }
        //   })
        // }
      })
    }else{
      props.dispatch(
        Notifications.error({
          message: props.translate('sensorTracingLimitError'),
          autoDismiss: 10
        })
      )
      setState((prevState) => ({ ...prevState, 
        activeStep: 0
      }))
      validatedCurrentStep(1, '', -1)
    }
  }

  const onCloseSubModal = ()=> {
    setState((prevState) => ({ ...prevState, 
      dm_isVisable: false,
      addOption: false,
      editOption: false
    }))
    props.onCloseModal()
  }

  const onDeleteJob = (item)=> {
    // fetch(`/api/jobs/${item.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/jobs/${item.id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {// eslint-disable-line  no-unused-vars
        // if (response.status === 200) {
          props.dispatch(removeJob(item))
          props.onCloseModal()
          setState((prevState) => ({ ...prevState, 
            selectedJob: ''
          }))
          props.dispatch(
            Notifications.success({
              message: props.translate('scheduleDeleted'),
              autoDismiss: 10
            })
          )
          // props.history.push('/schedules')
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, props.dispatch)})
  }

  // const isEmpty = obj => {
  //   for (let key in obj) {
  //     if (obj.hasOwnProperty(key)) return false // eslint-disable-line no-prototype-builtins
  //   }
  //   return true
  // }

  const handleChangePeriod = value => {
    let days = '1'
    if (value === 'daily') days = ['1', '2', '3', '4', '5', '6', '7']
    setState((prevState) => ({ ...prevState, 
        form: {
          ...prevState.form,
          period: value,
          days
        }
      }))
  }

  const handleChange = name => event => {
     if (name === 'start' || name === 'end' || name === 'triggerTime') {
      let timezone = 'Asia/Dubai'
      if (
        props.ServerSetting &&
        props.ServerSetting.attributes &&
        props.ServerSetting.attributes.timezone
      ) {
        timezone = props.ServerSetting.attributes.timezone
      }
      if (
        props.logInUser &&
        props.logInUser.attributes &&
        props.logInUser.attributes.timezone
      ) {
        timezone = props.logInUser.attributes.timezone
      }
      moment.tz.setDefault(timezone)

      setState((prevState) => ({ ...prevState, 
          form: {
            ...prevState.form,
            [name]: moment(
              moment().format('YYYY-MM-DD') + ' ' + event
            ).toISOString()
          }
        }))
    } else if (name === 'geoTimeThreshold') {
      setState((prevState) => ({ ...prevState, 
          form: {
            ...prevState.form,
            attributes: {
              ...prevState.form.attributes,
              [name]: event.target.value
            }
          }
        }
      ))
    } else if(name === 'attachmentType'){
      setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              attributes:{
                ...prevState.form.attributes,
                attachmentType:event.target.value
              }
            }
          }))
     } else if(name === 'withV1Merge'){
      setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              attributes:{
                ...prevState.form.withV1Merge,
                withV1Merge:event.target.checked
              }
            }
          }))
     }else if(name === 'enableRawDataAnalysis'){
      setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              attributes:{
                ...prevState.form.enableRawDataAnalysis,
                enableRawDataAnalysis:event.target.checked
              }
            }
          }))
     } 
     else if(name === 'includeTriggerDay'||name==="includeExtendedHours"||name==="includePartiallyInRange"){
      setState((prevState) => ({ ...prevState, 
       form: {
         ...prevState.form,
         [name]:event.target.checked
       }
     }))
}  else {
      const { target } = event
      setState((prevState) => ({ ...prevState, 
          form: {
            ...prevState.form,
            [name]: name === 'status' ? target.checked : target.value
          }
        }
      ))
    }
  }

  const handleChangeForEmails = (name, event, id) => {
    if (
      name === 'number' ||
      name === 'web' ||
      name === 'email' ||
      name === 'mailTo'
    ) {
      if (name === 'email') {
        let { email } = state.form
        email.map(item => {
          if (item.id === id) {
            item.text = event.target.value
          }
          return null
        })
        setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              email: email
            }
          }
        ))
        checkRequiredFields()
      } else if (name === 'mailTo') {
        setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              [name]: event.target.value,
              email: [{ id: 1, text: '' }]
            }
          }
        ))
        checkRequiredFields()
      } else if (name === 'number') {
        let { number } = state.form
        number.map(item => {
          if (item.id === id) {
            item.text = event.target.value
          }
          return null
        })
        setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              number: number
            }
          } ))
      } else {
        let { notificators } = state.form
        if (event.target.checked) {
          if (notificators.length) {
            notificators = notificators + ',' + name
          } else {
            notificators = name
          }
        } else {
          notificators = notificators.replace(name, '')
          notificators = notificators.replace(',', '')
        }
        setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              notificators: notificators
            }
          }))
      }
    } else {
      if (
        event.target.value === '' ||
        event.target.value === null ||
        event.target.value === undefined
      ) {
        delete state.form[name]
        setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form
            }
          }))
      } else {
        setState((prevState) => ({ ...prevState, 
            form: {
              ...prevState.form,
              [name]: event.target.value
            }
          }))
      }
    }
  }

  const onAddAnotherMail = type => {
    let { email, number } = state.form
    // frist check the formate of prevous emails
    let formateValidation = true
    if (type === 'email') {
      email.map(itam => {
        if (!validEmailPattern.test(itam.text)) {
          formateValidation = false
        }
        return null
      })
      if (formateValidation) {
        email.push({ id: email.length + 1, text: '' })
        setState((prevState) => ({ ...prevState, 
          form: {
            ...prevState.form,
            email: email
          }
        }))
      } else {
        props.dispatch(
          Notifications.error({
            message: props.translate('Enter your Email/Valid Email'),
            autoDismiss: 10
          })
        )
   
      }
    } else if (type === 'number') {
      number.map(itam => {
        if (!validNumberPattern.test(itam.text)) {
          formateValidation = false
        }
        return null
      })
      if (formateValidation) {
        number.push({ id: number.length + 1, text: '' })
        setState((prevState) => ({ ...prevState, 
          form: {
            ...prevState.form,
            number: number
          }
        }))
      } else {
        props.enqueueSnackbar(
          props.translate('previousNumberInvalid'),
          { autoHideDuration: 1000 }
        )
      }
    }
  }

  const deleteMail = (type, id) => {
    // frist check the formate of prevous emails
    let data = state.form[type]
    if (data.length === 1 && id === 1) {
      data[0].text = ''
      setState((prevState) => ({ ...prevState, 
        form: {
          ...prevState.form,
          [type]: data
        }
      }))
    } else {
      let newList = data.filter(itm => itm.id !== id)
      newList.map((item, ind) => {
        item.id = ind + 1
        return null
      })
      setState((prevState) => ({ ...prevState, 
        form: {
          ...prevState.form,
          [type]: newList
        }
      }))
    }
  }

  const spiltItem = item => {
    let result = []
    let res = item.split(',')
    if (res.length) {
      res.map((subitem, ind) => {
        if (subitem) {
          result.push({ id: ind + 1, text: subitem })
        }
        return null
      })
    }
    return result
  }

  const showJobDates = (from, to)=> {
    let timezone = 'Asia/Dubai'
    if (
      props.ServerSetting &&
      props.ServerSetting.attributes &&
      props.ServerSetting.attributes.timezone
    ) {
      timezone = props.ServerSetting.attributes.timezone
    }
    if (
      props.logInUser &&
      props.logInUser.attributes &&
      props.logInUser.attributes.timezone
    ) {
      timezone = props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)
    setState((prevState) => ({ ...prevState, 
        form: {
          ...prevState.form,
          start: from
            ? moment(from.format('YYYY-MM-DD HH:mm')).toISOString()
            : from,
          end: to ? moment(to.format('YYYY-MM-DD HH:mm')).toISOString() : to
        }
      }))
  }

  const onDayChange = (obj) => {
    let dayArry = []
    if ((state.form.days || []).includes(obj.id)) {
      dayArry = (state.form.days || []).filter(day => parseInt(day) !== parseInt(obj.id))
    } else {
      dayArry = (state.form.days || []).concat(obj.id)
    }

    setState((prevState) => ({ ...prevState, 
        form: {
          ...prevState.form,
          days: dayArry
        }
      }))
  }

  const onDayChange3 = (checked, obj) => {
    let dayArry = []
    if (checked) {
      dayArry = (state.form.attributes.controlDays || []).concat(
        parseInt(obj.id)
      )
    } else {
      dayArry = (state.form.attributes.controlDays || []).filter(
        day => day !== parseInt(obj.id)
      )
    }
    setState((prevState) => ({ ...prevState, 
        form: {
          ...prevState.form,
          attributes: { ...prevState.form.attributes, controlDays: dayArry }
        }
      }))
  }

  const onDayChange2 = (name, value) => {
    if (name === 'reportDuration') {
      let val = parseInt(value) || ''
      if (val && val < 1) {
        val = 1
      }
      if (val && val > 90) {
        val = 90
      }
      setState((prevState) => ({ ...prevState, 
          reportDuration: val
        }))
    } else {
      let val = parseInt(value) || ''
      if (val && val < 1) {
        val = 1
      }
      if (val && val > 31) {
        val = 31
      }
      setState((prevState) => ({ ...prevState, 
          form: {
            ...prevState.form,
            days: val
          }
        }))
    }
  }

  const checkRequiredFields = () =>{
    let {
      reportDuration,
      reportType,
      deviceIds,
      groupIds,
      vehicleIds,
      driverIds,
      selectedDate
    } = state
    let { description, type, triggerTime, period, days } = state.form
    let value = true
    let optionalPeriod = false
    if (period && period !== 'once') {
      if (days) {
        optionalPeriod = true
      } else {
        optionalPeriod = false
      }
    }
    if (period && period === 'once') {
      optionalPeriod = true
    }
    if (state.editOption) {
      value = !isEqual(state.form, state.selectedJob)
    }
    if (
      (state.editOption || state.validatedForm) &&
      description &&
      type &&
      triggerTime &&
      period &&
      optionalPeriod &&
      value
    ) {
      setState((prevState) => ({ ...prevState, 
        isVisableUserBtn: true
      }))
    } else {
      setState((prevState) => ({ ...prevState, 
        isVisableUserBtn: false
      }))
    }
    if (state.editOption && state.selectedJob) {
      if (
        reportType &&
        ((deviceIds && deviceIds.length) ||
          (groupIds && groupIds.length) ||
          (vehicleIds && vehicleIds.length) ||
          (driverIds && driverIds.length))
      ) {
        let newValue =
          selectedDate !== '' && selectedDate !== 'custom'
            ? selectedDate
            : reportDuration
        if (selectedDate === 'custom' && reportDuration === '') {
          setState((prevState) => ({ ...prevState, 
            isVisableUserBtn: false
          }))
        } else if (
          parseInt(newValue) !==
            state.selectedJob.attributes.reportDuration ||
          reportType !== state.selectedJob.attributes.rpTmplId ||
          deviceIds !== state.selectedJob.attributes.deviceIds ||
          groupIds !== state.selectedJob.attributes.groupIds ||
          vehicleIds !== state.selectedJob.attributes.vehicleIds ||
          driverIds !== state.selectedJob.attributes.driverIds ||
          value
        ) {
          setState((prevState) => ({ ...prevState, 
            isVisableUserBtn: true
          }))
        } else {
          setState((prevState) => ({ ...prevState, 
            isVisableUserBtn: false
          }))
        }
      } else {
        setState((prevState) => ({ ...prevState, 
          isVisableUserBtn: false
        }))
      }
      const rpId = state.selectedJob.attributes.rpTmplId;
      let tpl = props.templates.find(
        temp => temp.rpTmpl.id === rpId
      )
      tpl.rpTmplTblWrappers.map(i => {
        if(i.rpTmplTbl.rpId === 8){
          setState((prevState) => ({ ...prevState, 
            isSensorTracing: true
          }))
         }else{
          setState((prevState) => ({ ...prevState, 
            isSensorTracing: false
          }))
      }
      return null
    })

    }
  }

 const  selectedReportDateType = (name, event) => {
    const { target } = event
    setState((prevState) => ({ ...prevState, 
        [name]: target.value
      }))
  }

  const checkReportRequiredFields = () => {
    if (
      ((state.groupIds && state.groupIds.length) ||
        (state.vehicleIds && state.vehicleIds.length) ||
        (state.deviceIds && state.deviceIds.length) ||
        (state.driverIds && state.driverIds.length)) &&
      state.reportType &&
      state.form.description &&
      state.selectedDate
    ) {
      if (state.selectedDate === 'custom') {
        if (state.reportDuration) {
          setState((prevState) => ({ ...prevState,  validatedForm: true } ))
        } else {
          setState((prevState) => ({ ...prevState,  validatedForm: false } ))
        }
      } else {
        setState((prevState) => ({ ...prevState,  validatedForm: true }))
      }
    } else {
      setState((prevState) => ({ ...prevState,  validatedForm: false }))
    }
  }

  const selectedDevices = deviceIds => {
    setState((prevState) => ({ ...prevState, deviceIds, driverIds: [],
       groupIds: [], vehicleIds: [] }))
  }

  const selectedGroups = groupIds => {
    setState((prevState) => ({ ...prevState, groupIds, driverIds: [], deviceIds: [], vehicleIds: [] }))
  }

  const selectedDrivers = driverIds => {
    setState((prevState) => ({ ...prevState, driverIds, deviceIds: [], groupIds: [], vehicleIds: [] }))
  }

  const selectedVehicles = vehicleIds => {
    setState((prevState) => ({ ...prevState, vehicleIds, driverIds: [], deviceIds: [], groupIds: [] }))
  }
  
  const reportTypeChange = (event) => {
    let tpl = props.templates.find(
      temp => temp.rpTmpl.id === event.target.value
    )
    let allowFields = []
    if (
      tpl.rpTmpl &&
      tpl.rpTmpl.attributes &&
      tpl.rpTmpl.attributes.allowFields
    ) {
      allowFields = tpl.rpTmpl.attributes.allowFields
    }
    setState((prevState) => ({ ...prevState,
        reportType: event.target.value,
        reportUnits: allowFields[0],
        allowFields,
        eventType: allowFields.includes(state.eventType)
          ? state.eventType
          : allowFields.includes('notification')
          ? 'notification'
          : allowFields.includes('eventType')
          ? 'eventType'
          : ''
      }))



        let currentSteps = state.steps.filter(step => step.id < 3)

        if(allowFields.some(r=> ["eventType", "notification"].includes(r))) {
          // add notification step
          currentSteps.push({id: 3, title: "selectEventOrNotification"})
        }
        else if(allowFields.some(r=> ["geofence"].includes(r))) {
          // add notification step
          currentSteps.push({id: 4, title: "selectGeofence"})
        }
        else if(allowFields.some(r=> ["sensor", "digitalSensor"].includes(r))) {
          // add notification step
          currentSteps.push({id: 5, title: "selectSensor"})
        }

        currentSteps.push({id: 6, title: "executionSchedule"})
        setIsCallUpdate(true)

        // reportTypeSelection(state.reportUnits)
        if (
          (state.groupIds.length ||
            state.vehicleIds.length ||
            state.deviceIds.length ||
            state.driverIds.length) &&
          state.reportType &&
          state.selectedDate
        ) {
          setState((prevState) => ({ ...prevState,   validatedForm: true, steps: currentSteps }))
        } else {
          setState((prevState) => ({ ...prevState,   validatedForm: false, steps: currentSteps }))
        }

    tpl.rpTmplTblWrappers.map(i => {
        if(i.rpTmplTbl.rpId === 8){
           setState((prevState) => ({ ...prevState,
            isSensorTracing: true
          }))
         }else{
           setState((prevState) => ({ ...prevState,
            isSensorTracing: false
          }))
      }
      return null
    })
  }
 
  // const calcTime = (date, offset) => {
  //   let d = new Date(date)

  //   let utc = d.getTime() + d.getTimezoneOffset() * 60000

  //   let nd = new Date(utc + 3600000 * offset)

  //   return nd.toISOString()
  // }

  const convertIdsToint = data => {
    let result = []
    if (data.length) {
      data.map(item => {
        result.push(parseInt(item))
        return null
      })
    }
    return result
  }

  const createReport =()=>{

    let form = {
      rpTmplId: state.reportType,
      reportDuration:
        state.selectedDate === 'custom'
          ? parseInt(state.reportDuration)
          : parseInt(state.selectedDate),
      deviceIds: convertIdsToint(state.deviceIds),
      itemGroupIds: convertIdsToint(state.groupIds),
      vehicleIds: convertIdsToint(state.vehicleIds),
      driverIds: convertIdsToint(state.driverIds),
      geofenceIds: convertIdsToint(state.geofenceIds),
      itemGroupType: state.groupView === 2 ? itemGroupTypes[state.reportUnits] : '',
      notificationIds: state.notificationIds,
      sensors: state.sensorIds,
      sensorValues: state.sensorValues,
      types: state.types,
      geoTimeThreshold: (state.form.attributes.geoTimeThreshold || 0) * 60000,
      attachmentType:state.form?.attributes?.attachmentType || "excel",
      withV1Merge:state.form?.attributes?.withV1Merge||false,
      enableRawDataAnalysis:state.form?.attributes?.enableRawDataAnalysis||false,
    }
    return form
  }

  const handleTimeControl = (event, controlTime) => {
    if (controlTime === 'start' || controlTime === 'end') {
      let timezone = 'Asia/Dubai'
      if (
        props.ServerSetting &&
        props.ServerSetting.attributes &&
        props.ServerSetting.attributes.timezone
      ) {
        timezone = props.ServerSetting.attributes.timezone
      }
      if (
        props.logInUser &&
        props.logInUser.attributes &&
        props.logInUser.attributes.timezone
      ) {
        timezone = props.logInUser.attributes.timezone
      }
      moment.tz.setDefault(timezone)
      // const  finalDate =  moment(
      //   moment().format('YYYY-MM-DD') + ' ' + event
      // ).toISOString()
      //  const time1 = moment(finalDate).format('HH:mm');
      setState((prevState) => ({ ...prevState,
          form: {
            ...prevState.form,
            attributes :{
              ...prevState.form.attributes,
              // [controlTime === 'start' ? 'controlTimeStart' : 'controlTimeEnd']: time1
              [controlTime === 'start' ? 'controlTimeStart' : 'controlTimeEnd']: event.target.value
            }
          }
        }))
    } else {
      setState((prevState) => ({ ...prevState, controlTime }))
    }
  }

  const reportTypeSelection= (reportUnits) =>{
    setState((prevState) => ({ ...prevState,
      reportUnits
    }))
  }
const [isCallUpdate,setIsCallUpdate]=useState(false)
  useEffect(()=>{
    if(isCallUpdate){
      reportTypeSelection(state.reportUnits)
      setIsCallUpdate(false)
    }

  },[isCallUpdate,state.reportUnits])

  const goToNextStep = (step, type, index) => {
    validatedCurrentStep(step, type, index);
  }
  const goToBackStep = () => {
    const currentStep = state.activeStep;
    setState((prevState) => ({ ...prevState,activeStep: currentStep-1}) )
  }

  const validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {
      // check datetime
      if(state.form && state.form.description && state.selectedDate) {
        validated = true
        errorMsg= null
      }
      else if(state.form && !state.form.description) {
        validated = false
        errorMsg= "Please must enter  &apos;Report Name&apos;"
      }
      else if(!state.selectedDate) {
        validated = false
        errorMsg= "Please select  &apos;Report Interval&apos; from above"
      }
      
    }
    else if(step === 2) {
      
      // check template and unit, group or staff selected
      if(state.reportType) {
        if(state.allowFields.some(r=> ["device", "driver", "group", "vehicle"].includes(r)) && !state.groupIds.length && !state.vehicleIds.length && !state.deviceIds.length && !state.driverIds.length) {
          validated = false
          errorMsg=props.translate("Please must select anything from the above list section")
        }
        else {
          validated = true
          errorMsg= null
          
        }        
        
      }
      else {
        validated = false
        errorMsg= "Please select a  &apos;template&apos; from above"
       
      }
    }
    else if(step === 3) {
      // optional step
      // allow fields containing events, types make sure select any of.
      validated = true
      errorMsg= null
    }
    else if(step === 4) {
      // allow fields containing geofence, types make sure select any of. 
      validated = false
      errorMsg= "Please select a  &apos;geofence&apos; in above list"
      if(state.geofenceIds.length) {
        validated = true;
        errorMsg = null;
      }
    }
    else if(step === 5) {
      // allow fields containing sensor, types make sure select any of. 
      validated = false
      errorMsg= "Please enter a  &apos;sensor&apos; in above form"
      if(state.sensorIds.length) {
        validated = true;
        errorMsg = null;
      }
    }

    else if (step === 6) {
      validated = false
      errorMsg = "Please enter a  &apos;sensor&apos; in above form"
      if(!state.form.triggerTime) {
        errorMsg = " Please select  &apos;triggerTime&apos; in above form"
      }
      else {
        validated = true
        errorMsg = null
        if(state.editOption) {
          ud_submit(type)
        }
        else {
          ad_submit(type)
        }
      }
    }

  
    if(validated === true) {
      setState((prevState) => ({ ...prevState,activeStep: index + 1}))
    }

    state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      return null
    })

    setState((prevState) => ({ ...prevState,steps}) )

  }

  const selectedSensors = sensorIds => {
    if (sensorIds && sensorIds.length) {
      if(sensorIds.length === 1 && sensorIds[0] && sensorIds[0].value === 'allSensors') {
        setState((prevState) => ({ ...prevState,allSensors: true}))
      } else {
        setState((prevState) => ({ ...prevState,allSensors: false}))
      }
      setState((prevState) => ({ ...prevState, sensorIds: sensorIds.map(e => e.value), sensorValues: sensorIds }))
    } else {
      setState((prevState) => ({ ...prevState, sensorIds: [] }))
    }
    
  }

  const selectedGeofences = geofenceIds => {
    if (geofenceIds && geofenceIds.length) {
      setState((prevState) => ({ ...prevState, geofenceIds }))
    } else {
      setState((prevState) => ({ ...prevState, geofenceIds: [] }))
    }
  }
  const switchGroupView = type => {
    setState((prevState) => ({ ...prevState,
      groupView: type,
      groupIds:[],
      vehicleIds: [],
      deviceIds: [],
      driverIds: []
    }));
  }


  // render () {
    return (
      <div style={{ height: '100%' }}>
        {state.dm_isVisable && state.addOption && (
          <AddJobs
            {...state}
            {...props}
            handleTimeControl={handleTimeControl}
            onClose={onCloseSubModal}
            formSubmit={ad_submit}
            handleSelectedJobDate={handleSelectedJobDate}
            handleChange={handleChange}
            handleChangePeriod={handleChangePeriod}
            onDayChange={onDayChange}
            onDayChange2={onDayChange2}
            onDayChange3={onDayChange3}
            switchGroupView={switchGroupView}
            buttonText='sharedCreate'
            translate={props.translate}
            isHaveAccess='jobCreate'
            selectedReportDateType={selectedReportDateType}
            showJobDates={showJobDates}
            selectedNotifications={selectedNotifications}
            selectedDevices={selectedDevices}
            selectedVehicles={selectedVehicles}
            selectedEvents={selectedEvents}
            selectedGroups={selectedGroups}
            selectedDrivers={selectedDrivers}
            selectedGeofences={selectedGeofences}
            selectedSensors={selectedSensors}
            reportTypeChange={reportTypeChange}
            createReport={createReport}
            reportTypeSelection={reportTypeSelection}
            handleChangeForEmails={handleChangeForEmails}
            onAddAnotherMail={onAddAnotherMail}
            handleChangeType={handleChangeType}
            deleteMail={deleteMail}
            goToNextStep={goToNextStep}
            goToBackStep={goToBackStep}
          />
        )}

        {state.selectedJob && state.editOption && (
          <AddJobs
            {...state}
            {...props}
            handleTimeControl={handleTimeControl}
            onClose={onCloseSubModal}
            formSubmit={ud_submit}
            handleSelectedJobDate={handleSelectedJobDate}
            handleChange={handleChange}
            handleChangePeriod={handleChangePeriod}
            onDayChange={onDayChange}
            onDayChange2={onDayChange2}
            onDayChange3={onDayChange3}
            switchGroupView={switchGroupView}
            buttonText='update'
            translate={props.translate}
            isHaveAccess='jobUpdate'
            selectedReportDateType={selectedReportDateType}
            showJobDates={showJobDates}
            selectedNotifications={selectedNotifications}
            selectedDevices={selectedDevices}
            selectedVehicles={selectedVehicles}
            selectedEvents={selectedEvents}
            selectedGroups={selectedGroups}
            selectedDrivers={selectedDrivers}
            selectedGeofences={selectedGeofences}
            selectedSensors={selectedSensors}
            reportTypeChange={reportTypeChange}
            createReport={createReport}
            reportTypeSelection={reportTypeSelection}
            handleChangeForEmails={handleChangeForEmails}
            onAddAnotherMail={onAddAnotherMail}
            handleChangeType={handleChangeType}
            deleteMail={deleteMail}
            goToNextStep={goToNextStep}
            goToBackStep={goToBackStep}
            
          />
        )}
      </div>
    )
  // }
}

const mapState = state => ({
  jobs: state.jobs,
  themecolors: state.themeColors,
  templates: state.templates,
  logInUser: state.logInUsers
})
const mapStateToProps = connect(mapState)
export const JobsModal = mapStateToProps((withLocalize(jobsModal)))
