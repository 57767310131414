import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from './../common/TextField';
import { FormHelperText, InputAdornment } from '@mui/material';
import Button from '../../Components/common/Button'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import SimpleModalWrapped from './../common/Modal'
import { errorHandler, validEmailPattern } from '../../Helpers';
import instance from '../../axios';
import Notifications from 'react-notification-system-redux';
import { addUser } from '../../Actions/Users';
import { fetchDrivers } from '../../Actions/Devices';

const ValidationIcon = ({ isValid, message,
  themecolors
 }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {isValid ? (
      <CheckCircleIcon style={{ color: 'green', fontSize: 12 }} />
    ) : (
      <CancelIcon style={{ color: 'red', fontSize: 12 }} />
    )}
    <span style={{ marginLeft: '5px',color:themecolors?.textColor||"white" }}>{message}</span>
  </div>
);

const PasswordRequirements = ({
  hasLengthValid,
  hasNumberValid,
  hasUpperCaseValid,
  hasLowerCaseValid,
  hasSpecialCharValid,
  translate,
  themecolors
}) => (
  <FormHelperText style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
    <ValidationIcon
      isValid={hasLengthValid}
      themecolors={themecolors}
      message={translate('Length must be at least 8 characters')}
    />
    <ValidationIcon
      isValid={hasNumberValid}
      themecolors={themecolors}
      message={translate('Must contain at least one Number')}
    />
    <ValidationIcon
      isValid={hasUpperCaseValid}
      themecolors={themecolors}
      message={translate('Must contain at least one Uppercase letter')}
    />
    <ValidationIcon
      isValid={hasLowerCaseValid}
      themecolors={themecolors}
      message={translate('Must contain at least one Lowercase letter')}
    />
    <ValidationIcon
      isValid={hasSpecialCharValid}
      themecolors={themecolors}
      message={translate('Must contain at least one Special character (!@#$%^&*(),.?:{}|<></>)')}
    />
  </FormHelperText>
);
const userFormDefault = {
  name: '',
  email: '',
  password:"",
  confirmPassword: '',
  phone:""
}

const removeFieldsObj = (obj, fields) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (!fields.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
const AddDriverUserModal = (props) => {
    const themecolors = useSelector((state) => state.themeColors)
    const allUsers = useSelector((state) => state.users)


    const [state,setState]=useState({
      userForm:userFormDefault,
      hasLengthValid:false,
      hasLowerCaseValid:false,
      hasUpperCaseValid:false,
      hasNumberValid:false,
      hasSpecialCharValid:false,
      isConfirmPassChk:false,
      isPasswordValid:false,
      isLinkUser:false

    })
  
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmitForm=()=>{
      const updatedObjUser = removeFieldsObj(state.userForm, ["confirmPassword"]);
      const userData = { ...updatedObjUser, userType: 1 };
    
        instance({
          url: `/api/drivers/${props.selecteditem?.id}/register-user`,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          data: userData,
        })
          .then(user => {
            const parent = {
              parentId: props.logInUser?.id,
              parentName: props.logInUser?.name,
            };
            const updatedUser = { ...user, ...parent };
            props.dispatch(addUser(updatedUser));
            fetchDrivers(props.dispatch,props.logInUser)
            props.onCloseModal()
            props.fetchMoreItems()
          })
          .catch(error => {
            if (error.message.includes("uk_driver_uniqueid")) {
              props.dispatch(
                Notifications.error({
                  message: "Driver Unique ID Already Exists.",
                  autoDismiss: 10,
                })
              );
            } else if (error.message.includes("uk_user_email")) {
              props.dispatch(
                Notifications.error({
                  message: "Email Already Exists.",
                  autoDismiss: 10,
                })
              );
 
        }else{
            errorHandler(error, props.dispatch);
          }
          });
      };

    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prev) => !prev);
    };
    const handleChangeUser = (name) => (event) => {
      const { target } = event;
      let value;
    
      if (name === 'licenseExpDate' || name === 'dateOfBirthGregorian' || name === 'expirationTime') {
        value = event.toISOString();
      } else {
        value = target.value;
      }
    
      setState((prevState) => ({
        ...prevState,
        userForm: {
          ...prevState.userForm,
          [name]: value,
        },
      }));
    };

    const verifyPassword = () => {
      const { password, confirmPassword } = state.userForm;
      const minLength = 8;
      const hasLowerCase = /[a-z]/;
      const hasUpperCase = /[A-Z]/;
      const hasNumber = /\d/;
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
      const hasLengthValid = password?.length >= minLength;
      const hasLowerCaseValid = hasLowerCase.test(password);
      const hasUpperCaseValid = hasUpperCase.test(password);
      const hasNumberValid = hasNumber.test(password);
      const hasSpecialCharValid = hasSpecialChar.test(password);
      const isPasswordMatch = password?.trim() !== '' && confirmPassword?.trim() !== '' && password === confirmPassword;
      setState((prevState)=>({
...prevState,
hasLengthValid,
hasLowerCaseValid,
hasUpperCaseValid,
hasNumberValid,
hasSpecialCharValid,
isConfirmPassChk: isPasswordMatch,
isPasswordValid: hasLengthValid && hasLowerCaseValid && hasUpperCaseValid && hasNumberValid && hasSpecialCharValid,
      }))
  
    
      if (hasLengthValid && hasLowerCaseValid && hasUpperCaseValid && hasNumberValid && hasSpecialCharValid) {
        checkRequiredFieldsUser();
      }
    };

    const checkRequiredFieldsUser = () => {
      const { name, email, password, confirmPassword } = state.userForm;
      const isFormValid =
        name &&
        email &&
        validEmailPattern.test(email) &&
        (!state.isLinkUser ? password === confirmPassword && state.isPasswordValid : true);
    
      setState((prevState) => ({
        ...prevState,
        isVisableUserSubmit: isFormValid,
      }));
    };
    

    useEffect(()=>{
      checkRequiredFieldsUser()
    },[state.userForm.name,state.userForm.email,state.isPasswordValid])

    useEffect(()=>{
verifyPassword()
    },[state.userForm.password,state.userForm.confirmPassword])

    useEffect(() => {
      if (props.selecteditem && allUsers.length > 0) {
        const isLinkUser = allUsers.find((item) => item.id === props.selecteditem?.userId);
        setState((prevState) => ({
          ...prevState,
          userForm: isLinkUser || userFormDefault,
          isLinkUser: !!isLinkUser,
        }));
      }
    }, [props.selecteditem, allUsers]);
    

  const renderPasswordFields = () => (
    <>
      <Grid item sm={6} xs={12}>
        <TextField
          id="password"
          label={props.translate('userPassword')}
          type={showPassword ? 'text' : 'password'}
          required
          value={state.userForm?.password || ''}
          onChange={handleChangeUser('password')}
          variant="outlined"
          margin="dense"
          fullWidth
          InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <div
                        style={{ cursor: 'pointer',marginTop:"3px" }}
                        onClick={handleTogglePassword}
                    >
                        {showPassword ? (
                            <VisibilityOffOutlinedIcon style={{ color:themecolors?.menuActiveBackground }} />
                        ) : (
                            <VisibilityOutlinedIcon style={{ color:themecolors?.menuActiveBackground}} />
                        )}
                    </div>
                </InputAdornment>
            ),
        }}

        />
        <PasswordRequirements
          hasLengthValid={state.hasLengthValid}
          hasNumberValid={state.hasNumberValid}
          hasUpperCaseValid={state.hasUpperCaseValid}
          hasLowerCaseValid={state.hasLowerCaseValid}
          hasSpecialCharValid={state.hasSpecialCharValid}
          translate={props.translate}
          themecolors={themecolors}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          id="confirmPassword"
          label={props.translate('confirmPassword')}
          type={showConfirmPassword ? 'text' : 'password'}
          error={state.userForm.password !== state.userForm.confirmPassword}
          required
          value={state.userForm.confirmPassword}
          onChange={handleChangeUser('confirmPassword')}
          variant="outlined"
          margin="dense"
          fullWidth
          InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <div
                        style={{ cursor: 'pointer',marginTop:"3px" }}
                        onClick={handleToggleConfirmPassword}
                    >
                        {showConfirmPassword ? (
                            <VisibilityOffOutlinedIcon style={{ color:themecolors?.menuActiveBackground }} />
                        ) : (
                            <VisibilityOutlinedIcon style={{ color:themecolors?.menuActiveBackground}} />
                        )}
                    </div>
                </InputAdornment>
            ),
        }}

        />
        <FormHelperText style={{ color: themecolors.textColor }}>
          <ValidationIcon
            isValid={state.isConfirmPassChk}
      themecolors={themecolors}
            message={props.translate('Both Passwords must be Equal')}
          />
        </FormHelperText>
      </Grid>
    </>
  );

    return (
      <div style={{ height: '60vh' }}>
        <SimpleModalWrapped
            title={props.translate('User Link With Driver')}
            themecolors={themecolors}
            visable
            btnCancelNotShow
            notShowAttributesBtn
            // isButtonVisable
            formSubmit={props.formSubmit}
            modalControle={props.onClose}
            buttonText={props.buttonText}
            btnCloseText='sharedBack'
            isVisableBtn={props.isVisableUserBtn}
            selectedItemId={handleSubmitForm}
            isLoading={props.isLoading}
                 content={
                  <>
    <Grid container spacing={1}>
        {/* Left side: Name, Email, and Phone */}
        <Grid item sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <TextField
                id="name"
                label={props.translate('sharedName')}
                type="text"
                required
                value={state.userForm.name || ''}
                onChange={handleChangeUser('name')}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="email"
                label={props.translate('userEmail')}
                type="text"
                required
                value={state.userForm.email || ''}
                onChange={handleChangeUser('email')}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Right side: Password and Driver License */}
        <Grid item sm={12} xs={12}>
          <Grid container spacing={1}>
            {!state.isLinkUser &&
          renderPasswordFields()}
              <Grid item sm={6} xs={12}>
                <TextField
                  id="phone"
                  label={props.translate('phone')}
                  type="text"
                  value={state.userForm.phone}
                  onChange={handleChangeUser('phone')}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
          
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ borderTop: `1px outset ${themecolors?.themeLightColor}`, padding: 2, mt: 2 }}>
  {!state.isLinkUser && (
    <Button 
      sx={{ ml: "auto", mt: "0.5rem" }} 
      disabled={!state.isVisableUserSubmit}
      onClick={handleSubmitForm}
    >
      Submit
    </Button>
  )}
</Grid>




      </Grid>
      </>
      }
        />
      </div>

    )
}
export default AddDriverUserModal