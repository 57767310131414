import React, { useEffect, useState } from 'react'
import Layout from '../../Layout';
import { WorkFlowForm } from '../../Components/WorkFlow/WorkFlowForm';
import instance from '../../axios';
import Table from '../../Components/common/tableWithBackEndPagination'
import { checkPrivileges, errorHandler } from '../../Helpers';
import Notifications from 'react-notification-system-redux'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag';
import Button from '../../Components/common/Button';
// import { FormControlLabel } from '@mui/material';
import axios from 'axios'
// import { FormControlLabel, Grid } from '@material-ui/core'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Checkbox from '../../Components/common/Checkbox'
// import MenuItem from '../../Components/common/MenuItem'
// import Menu from '../../Components/Menu/CommonMenu'
import { useDispatch, useSelector } from 'react-redux';
// import ExportExcel from '../../Components/common/ExportExcel';
import {useHistory} from "react-router-dom"
const CancelToken = axios.CancelToken
let source = ''

const DeliveryNote = (props) => {
  const [state, setState] = useState({
    mode: '',
    initFetch: false,
    metrixData: [],
    categoriesList: [],
    invoicesList: [],
    showTable: true,
    showForm: false,
    onDeleteConfirmation: false,
    selectedItem: '',
    currentPage: 1,
    pagesize: 50,
    countChecked: 0,
    anchorEl: '',
    statusFilter: [],
    filterStatusType: '',
    isSearchApi:false,
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 1,
      hasNext: true,
      searchText: ''
    },
    deliveryNoteNumber:"",
    deliveryNoteRefNumber:"",
    plateNumber:""
  }
  )
const history=useHistory()
  const logInUser = useSelector((state) => state.logInUsers)
  const devices2 = useSelector((state) => state.devices2)
  const themecolors = useSelector((state) => state.themeColors)
  const dispatch = useDispatch()
  const fetchMoreItems = () => {
    fetchData(logInUser, state.currentPage, state.pagesize)
  }

  const generateQueryString = (page,perPage) => {
    let queryString = `?page=${page}&limit=${perPage}`;
    if (state.deliveryNoteNumber) queryString += `&deliveryNoteNumber=${state.deliveryNoteNumber}`;
    if (state.deliveryNoteRefNumber) queryString += `&deliveryNoteRefNumber=${state.deliveryNoteRefNumber}`;
    if (state.plateNumber) queryString += `&plateNumber=${state.plateNumber}`;
    return queryString;
  };

  const fetchData = (userId, page, perPage, reset, sidebar, sidebarData) => {
    source = CancelToken.source()
    // const query = 'all=true'
    let searchText = state.searchText

    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }
    if (reset) {
      setState((prevState) => ({
        ...prevState,
        itemPagination: { ...prevState.itemPagination, items: [] }
      }))
    }
    let sidebarURL = ''
    if (sidebar) {
      sidebarURL = `/api/deliverynotes/get?page=${page}&limit=${perPage}&to=${sidebarData.to}&from=${sidebarData.from}${sidebarData.selectedvehicleId}`;
    }
    instance({
      url: sidebar ? sidebarURL : `/api/deliverynotes/get${generateQueryString(page,perPage)}`,
      method: 'GET',
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      setState((prevState) => ({
        ...prevState,
        invoicesList: response,
      }))
    }).catch(error => {
      console.error(error)
      // errorHandler(error,props.dispatch)
    })
  }



  const modelControl = () => {
    setState((prevState)=>({
      ...prevState,
      mode: '',
      showTable: true,
      showForm: false,
    }))
  }

  const handleChangeRowsPerPage = (value) => {
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({
      ...prevState,
      pagesize: value,
      currentPage: 1
    }))
  }

  const handleChangePage = (value) => {
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({
      ...prevState,
      currentPage: value
    }))
  }
  const setPagination = (user) => {
    const { invoicesList } = state
    if (invoicesList && invoicesList.data && invoicesList.data.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / state.pagesize
      let IsFloate = checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pageSize = state.pagesize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }


  const checkFloteNumber = (n) => {
    return Number(n) === n && n % 1 !== 0
  }

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      onDeleteConfirmation: false,
      selectedItem: ""
    }))
  }

  const onDelete = () => {
    if (state.selectedItem && state.selectedItem.id) {
      instance({
        url: `/api/workflows//${state.selectedItem.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response) {
            dispatch(
              Notifications.success({
                message: props.translate('savedWorkFlowIsDeleted'),
                autoDismiss: 10
              })
            )
            setState((prevState) => ({
              ...prevState,
              onDeleteConfirmation: false,
              selectedItem: ''
            }))
            fetchMoreItems()
          }
        }).catch(error => { errorHandler(error, dispatch) })
    }
  }


  const handleSearchDeliveryNote=(name,value)=>{
    setState((prevState)=>({
        ...prevState,
        [name]:value
    }))
  }

  const handleSubmitSearch=()=>{
    fetchData(logInUser,1,20)
  }

  const searchItems = (e) => {
    console.error("=======eee",e)
    if (source) {
      source.cancel()
    }
    setState((prevState) => ({
      ...prevState,
      searchText: e
    }))
  }



  useEffect(() => {
    fetchData(
      logInUser,
      state.currentPage,
      state.pagesize
    )
  }, [state.pagesize, state.currentPage, state.filterStatusType])

  const endMessage = (
    <p style={{ textAlign: 'center', color: '#ccc' }}>
      -- {props.translate('end')} --
    </p>
  )
  useEffect(() => {
    if (!checkPrivileges("deliverynote")) {
      history.push("/");
    }
  }, [history]);
  return (
    <div>
      {
        checkPrivileges("deliverynote")?
      <Layout
        endMessage={endMessage}
        classFromChildren='has-padding'
        fetchMoreItems={fetchMoreItems}
        itemPagination={{ ...state.itemPagination }}
        fetchData={fetchData}
        {...props}
        {...state}
      >
        {state.showTable &&
          (<div className='main-content-page'>
            <div
              style={{
                background: themecolors.backgroundColor,
                color: themecolors.textColor,
                borderRadius: 6,
                padding: 16
              }}
            >
              {state.showTable ? (
                <Table
                  rows={state?.invoicesList?.data || []}
                  pagination={setPagination(state?.invoicesList || {})}
                  handleChangeRowsPerPage={(n) => handleChangeRowsPerPage(n, 'invoicesList')}
                  handleChangePage={(n) => handleChangePage(n, 'invoicesList')}
                  logInUser={logInUser}
                  status={false}
                  completeMark={false}
                  themecolors={themecolors}
                  translate={props.translate}
                  isDeliveryNote
                  SearchItem={searchItems}
                  handleSearchDeliveryNote={handleSearchDeliveryNote}
                  device2={devices2}
                  workFlowStatus
                  createButton={
                    checkPrivileges('workflowCreate') && (
                      <div style={{ marginLeft: 10 ,display:"flex",alignItems:"center",gap:"1rem"}}>
                        <Button onClick={handleSubmitSearch}>
                          {props.translate('Search')}
                        </Button>

             {/* <ExportExcel
              type='Excel'
              // fileName={fileName}
              // data={data}
              title= 'Excel'
              sample={false}
              dispatch={props.dispatch}
              isMaintenance={props.isMaintenance}
              ServerSetting={props.ServerSetting}
              logInUser={props.logInUser}
              translate={props.translate}
              isDeliveryNote
            /> */}
                      </div>
                    )
                  }
                //   isFilter={
                //     (<Filter
                //       countChecked={state.countChecked}
                //       anchorEl={state.anchorEl && state.anchorEl}
                //       handleChangeFilter={handleChangeFilter}
                //       handleClickFilter={handleClickFilter}
                //       handleCloseFilter={handleCloseFilter}
                //       translate={props.translate}
                //       statusFilter={state.statusFilter}
                //     />)
                //   }
                  rowDefinition={[
                    // {
                    //   id: 'refNum',
                    //   numeric: false,
                    //   disablePadding: false,
                    //   label: `${props.translate('deliveryNote')} #`
                    // },
                    {
                      // id: 'vehicleType',
                      id: 'deliveryNoteRefNumber',
                      numeric: false,
                      disablePadding: false,
                      label: `${props.translate('deliveryNote')} Ref #`
                    },
                    // {
                    //   id: 'startPoint',
                    //   numeric: false,
                    //   disablePadding: false,
                    //   label: props.translate('bookingId')
                    // },
                    {
                      id: 'workOrderNumber',
                      numeric: false,
                      disablePadding: false,
                      label: `${props.translate('workOrder')} #`
                    },
                    {
                      id: 'contractNumber',
                      numeric: false,
                      disablePadding: false,
                       label: `${props.translate('customerRef')} #`
                    },
                    {
                      id: 'plateNumber',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('vehicleLicensePlate')
                    },
                    {
                      id: 'serviceRefDate',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('serviceDate')
                    },
                    {
                      id: 'saleTotalAmount',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('Amount')
                    },
                    {
                      id: 'saleVATAmount',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('VAT Amount')
                    },
                    {
                      id: 'saleNetAmount',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('Total Amount')
                    },
                    //  {
                    //   id: 'totalCost',
                    //   numeric: false,
                    //   disablePadding: false,
                    //   label: props.translate('totalCost')
                    // },
                    {
                      id: 'odometerReading',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('Mileage km')
                    },
                    // {
                    //   id: 'status',
                    //   numeric: false,
                    //   disablePadding: false,
                    //   label: props.translate('status')
                    // },
                    // {
                    //   id: 'interCity',
                    //   numeric: false,
                    //   disablePadding: false,
                    //   label: props.translate('tripType')
                    // },
                    {
                      id: 'created',
                      numeric: false,
                      disablePadding: false,
                      label: props.translate('Recieved Date')
                    },
                    {
                      id: "viewDeliveryNote",
                      numeric: false,
                      disablePadding: false,
                      label:props.translate("viewInvoice"),
                      hide: false,
                    },
                    // {
                    //   id: 'endTime',
                    //   numeric: false,
                    //   disablePadding: false,
                    //   label: props.translate('endTime')
                    // }
                  ]}
                />
              ) : null}

              {/* {state.mode === '' ? (
              <EmptyState
                text={props.translate('noCommandSelected')}
              />
            ) : null} */}
            </div>
          </div>)
        }

        {state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={onCancel}
            onOk={onDelete}
            title={props.translate('areYouWantToDelete')}
          // children={props.item.description}
          />
        )}

        {state.mode === 'create' ? (
          <WorkFlowForm
            themecolors={themecolors}
            modelControl={modelControl}
            translate={props.translate}
            metrixData={state?.metrixData || []}
            categoriesList={state?.categoriesList || []}
            fetchMoreItems={fetchMoreItems}
            createMode
          />
        ) : null}
        {state.mode === 'edit' ? (
          <WorkFlowForm
            formData={state.form}
            themecolors={themecolors}
            modelControl={modelControl}
            translate={props.translate}
            metrixData={state?.metrixData || []}
            categoriesList={state?.categoriesList || []}
            fetchMoreItems={fetchMoreItems}
            editMode
          />
        ) : null}

      </Layout>:
      null
      }

    </div>
  )
}


export default DeliveryNote


// const Filter = (props) => {
//   const html = (
//     <div>
//       <div
//         href={null}
//         aria-controls='simple-menu'
//         aria-haspopup='true'
//         onClick={props.handleClickFilter}
//       >
//         <p className='v-tab-heading pull-left' style={{ width: 100 }}>
//           {props.translate('selected')}{": "}{props.countChecked}
//         </p>
//         <ArrowDropDownIcon style={{ color: '#FFFFFF' }} />
//       </div>
//       <Menu
//         id='simple-menu'
//         anchorEl={props?.anchorEl}
//         open={Boolean(props?.anchorEl)}
//         onClose={props.handleCloseFilter}
//       >
//         <MenuItem component={FormControlLabel} style={{ margin: 0 }}
//           control={<Checkbox
//             name='Pending'
//             checked={props.statusFilter?.pending}
//             onChange={(e) => props.handleChangeFilter('pending', e)}
//             canAssign
//             canRemove
//           />}
//           label={<span style={{ marginLeft: 8 }}>{props.translate('scheduled')}</span>}
//         />
//         <MenuItem component={FormControlLabel} style={{ margin: 0 }}
//           control={<Checkbox
//             name='Cancelled'
//             checked={props.statusFilter?.cancelled}
//             onChange={(e) => props.handleChangeFilter('cancelled', e)}
//             canAssign
//             canRemove
//           />}
//           label={<span style={{ marginLeft: 8 }}>{props.translate('cancelled')}</span>}
//         />
//         <MenuItem component={FormControlLabel} style={{ margin: 0 }}
//           control={<Checkbox
//             name='inprogress'
//             checked={props.statusFilter?.inprogress}
//             onChange={(e) => props.handleChangeFilter('inprogress', e)}
//             canAssign
//             canRemove
//           />}
//           label={<span style={{ marginLeft: 8 }}>{props.translate('inProgress')}</span>}
//         />
//         <MenuItem component={FormControlLabel} style={{ margin: 0 }}
//           control={<Checkbox
//             name='Completed'
//             checked={props.statusFilter?.completed}
//             onChange={(e) => props.handleChangeFilter('completed', e)}
//             canAssign
//             canRemove
//           />}
//           label={<span style={{ marginLeft: 8 }}>{props.translate('completed')}</span>}
//         />
//       </Menu>
//     </div>
//   );

//   return <div className="v-filter-field">{html}</div>;
// };