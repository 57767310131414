import React, { useState } from 'react';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '../common/MenuItem';
import { errorHandler } from '../../Helpers';
import CustomDialog from './Dialog';
import MaintenanceFilter from './MaintenanceFilter';

import Button from '../common/Button';

import { GetApp } from '@mui/icons-material';

const ExportExcel = (props) => {
  const [state, setState] = useState({
    openMaintenanceModal: false,
    loader: false,
    countChecked: 0,
    anchorEl: null,
  });

  const manageExportMaintenance = () => {
    setState(prevState => ({
      ...prevState,
      openMaintenanceModal: true,
    }));
  };

  const onCloseDueMaintenanceModel = () => {
    setState(prevState => ({
      ...prevState,
      openMaintenanceModal: false,
      countChecked: 0,
      anchorEl: null,
    }));
  };

  const formSubmit = (data) => {
    const { selectedAreaId, from, to, selectedMaintenanceId, selectedvehicleId } = data;
    setState(prevState => ({ ...prevState, loader: true }));
    
    let api, Accept, fileName = 'services';
    
    if (props.type === 'PDF') {
      Accept = 'application/pdf';
    } else {
      Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }

    api = `/api/services/export?sample=false${selectedMaintenanceId}&from=${from}&to=${to}${selectedvehicleId}${selectedAreaId}`;
    
    axios({
      method: 'GET',
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: 'blob',
    })
    .then(response => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      if(props.type === 'PDF'){
        a.setAttribute('download', `${fileName}.pdf`);
      }else{
        a.setAttribute('download', `${fileName}.xlsx`);
      }
      document.body.appendChild(a);
      a.href = url;
      a.click();
      setState(prevState => ({ ...prevState, loader: false }));
    })
    .catch(error => {
      errorHandler(error, props.dispatch);
    });
  };

  return (
    <div>
      <Tooltip title={props.translate('downloadExcel')}>
        {
          props.isDeliveryNote?
          <Button
          // disabled={!props.dataLength}
          color="inherit"
          size="small"
          style={{ marginRight: "20px" }}
          onClick={() => exportFile(props.fileName, props.polygon, props)}
        >
          <GetApp/>
          {props.title}
        </Button>:
            <MenuItem
            style={{ minWidth: 'inherit' }}
            component="label"
            onClick={props.isMaintenance ? manageExportMaintenance : () => exportFile(props.fileName, props.polygon, props)}
          >
            {props.fileName !== 'geofences' ?
              `${props.translate('download')} ${props.translate(props.title)}` :
              props.polygon ? `${props.translate('Polygon Geofences')} ${props.translate(props.title)} ${props.translate('download')}` :
              `${props.translate('Circle Geofences')} ${props.translate(props.title)} ${props.translate('download')}`
            }
          </MenuItem>
        }
    
      </Tooltip>
      {state.openMaintenanceModal && (
        <CustomDialog
          title={props.translate('downloadServices')}
          visible={true}
          onClose={onCloseDueMaintenanceModel}
          bodyPadding={10}
          isVisibleBtn
          noFullScreen
          disableCloseBtn
        >
          <aside className='sidebar-bg-layer'>
            <MaintenanceFilter
              {...props}
              formSubmit={formSubmit}
              gridSize={6}
              loader={state.loader}
            />
          </aside>
        </CustomDialog>
      )}
    </div>
  );
};

export default ExportExcel;

export const exportFile = (fileName, Dform, props = {}) => {
  let api, Accept;
  
  if (['vehicles', 'drivers', 'devices', 'binders', 'users', 'services'].includes(fileName)) {
    api = `/api/${fileName}/export?sample=${props.sample}`;
  } else if (fileName === 'geofences' && !props.polygon) {
    api = `/api/geofences/export/circle?sample=${props.sample}`;
  } else if (fileName === 'geofences' && props.polygon) {
    api = `/api/geofences/export/polygon?sample=${props.sample}`;
  } else if (fileName === 'Dlogs') {
    api = `/api/positions/export?from=${Dform.from}&to=${Dform.to}&deviceId=${Dform.device}&detail=${Dform?.detail || false}`;
  }

  if (props.type === 'PDF') {
    Accept = 'application/pdf';
  } else {
    Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }

  axios({
    method: 'GET',
    url: api,
    headers: {
      Accept: Accept,
    },
    responseType: 'blob',
  })
  .then(response => {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([response.data]));
    if(props.type === 'PDF'){
      a.setAttribute('download', `${fileName}.pdf`);
    }else{
      a.setAttribute('download', `${fileName}.xlsx`);
    }
    document.body.appendChild(a);
    a.href = url;
    a.click();
  })
  .catch(error => {
    errorHandler(error, props.dispatch);
  });
};
