import React, { useState, useEffect,useRef } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import MarkerMap from '../../Components/Maps'
// import CustomMarker from '../../Components/Maps/CustomMarker'
import { MapTooltip } from '../../Components/Maps/MapTooltip'
import Layout from '../../Layout'
import isEqual from 'react-fast-compare'
import Grid from '@mui/material/Grid'
import { DriversModal } from '../../Components/Drivers/driversModal'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import {
  MapContainer,
  TileLayer,
  Tooltip,
  Marker,
  ZoomControl
} from 'react-leaflet'
import L from 'leaflet'
import { setTrackId } from '../../Actions/Devices'
import { removeDriver } from '../../Actions/Drivers'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import MainUnitModal from './MainUnitModal'
function importAll(r) {
  return r.keys().map(r)
}

const images = importAll(
  require.context('./../../images', false, /\.(png|jpe?g|svg)$/)
)

const Drivers = (props) => {

  const mapRef = useRef(null)
  let map = mapRef.current;

  const [state , setState] = useState({
    selecteditem: '',
    isVisable: false,
    showItemDetail: false,
    activeOperation: '',
    driverAddress: '',
    driverLat: '',
    driverLon: '',
    selecteditemId: '',
    trackersApiResponce: false,
    multiTrackers: '',
    initFetch: false,
    currentPage: 1,
    pagesize: 50,
    lat: 0,
    lng: 0,
    zoom: 3,
    minZoom: 3,
    animCount: 0,
    assigned: false,
    tracking: false,
    applied: false,
    allDriverLocation: {},
    driverLocation: {},
    resourceList: false,
    linkResource: false,
    itemPagination: {
      items: [],
      total: 0,
      currentPage: 0,
      currentDevice: props.deviceId,
      hasNext: true,
      searchText: ''
    }
  })

  useEffect(() => {
    if (props.logInUser && props.logInUser.latitude && props.logInUser.longitude && !state.assigned) {
      setState(prevState => ({
        ...prevState,
        assigned: true,
        lat: props.logInUser.latitude,
        lng: props.logInUser.longitude
      }));
    }
  }, [props.logInUser, state.assigned]);

  useEffect(() => {
    const updateState = () => {
      const { ServerSetting, logInUser, drivers, trackId, mapLayer, deviceRelatedData, match, allComputedAttributes } = props;
      const { showItemDetail } = state;

      if (ServerSetting?.zoom) {
        setState(prevState => ({
          ...prevState,
          zoom: ServerSetting.zoom,
          lat: ServerSetting.latitude,
          lng: ServerSetting.longitude
        }));
      }

      if (logInUser?.zoom) {
        setState(prevState => ({
          ...prevState,
          zoom: logInUser.zoom,
          lat: logInUser.latitude,
          lng: logInUser.longitude
        }));
      }

      if (logInUser?.id && drivers.length && !state.initFetch) {
        setState(prevState => ({ ...prevState, initFetch: true }));
        fetchData(props);
      }

      if (isEqual(drivers, props.drivers) && drivers.length && props.drivers.length) {
        fetchData(props);
      }

      if (props.trackId !== trackId) {
        setState(prevState => ({
          ...prevState,
          allDriverLocation: {},
          animCount: 0
        }));
        calculate(props);
      } else {
        calculate(props);
      }

      if (showItemDetail && deviceRelatedData && Object.values(deviceRelatedData).length && match?.params?.id) {
        const driverLocation = Object.values(deviceRelatedData).find(({ driverId }) =>
          driverId && driverId === parseInt(match.params.id) && allComputedAttributes
        );
        if (driverLocation?.latitude && driverLocation?.longitude) {
          setState(prevState => ({ ...prevState, driverLocation: driverLocation || null }));
          if (driverLocation && props.trackId > 0) {
            if (map) {
              map.setMaxZoom(16).fitBounds([[driverLocation.latitude, driverLocation.longitude]]);
              map.setMaxZoom(mapLayer.maxZoom);
              setTimeout(() => {
                setState(prevState => ({ ...prevState, applied: true, animCount: 1 }));
              }, 200);
            }
          }
        }
      }
    };

    updateState();
  }, [props.ServerSetting, props.logInUser, props.drivers, props.trackId, props.mapLayer, props.deviceRelatedData, props.match, props.allComputedAttributes, state.assigned, state.showItemDetail, state.initFetch]);


useEffect(() => {
  if (state.searchText) { fetchData(props) }
}, [state.searchText])

useEffect(() => {
  if (state.selecteditem && state.activeOperation === 'edit') {
    getMultiDevice(state.selecteditem.id);
  }
}, [state.selecteditem, state.activeOperation]);

// Effect for cleanup

useEffect(() => {
  return () => {
    setState(prevState => ({
      ...prevState,
      selecteditem: '',
      isVisable: false,
      showItemDetail: false,
      activeOperation: '',
      driverAddress: '',
      driverLat: '',
      driverLon: '',
      selecteditemId: '',
      trackersApiResponce: false,
      multiTrackers: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      animCount: 0,
      allDriverLocation: {},
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: props.deviceId,
        hasNext: true,
        searchText: ''
      }
    }));
  };
}, []);

const mapReference = el => {
  if (el) {
    map = el
  }
}
const showResources = (type) => {
  setState(prevState => ({
    ...prevState,
    resourceList: type
  }))
}

const addResource = () => {
  setState(prevState => ({
    ...prevState,
    isVisable: true,
    showItemDetail: false,
    activeOperation: 'addResource',
    selecteditem: '',
    driverAddress: '',
    driverLat: '',
    driverLon: ''
  }))
}
const onEditResource = (item) => {
  setState(prevState => ({
    ...prevState,
    isVisable: true,
    showItemDetail: false,
    activeOperation: 'editResource',
    selecteditem: item,
    driverAddress: '',
    driverLat: '',
    driverLon: ''
  }))
}

const onLinkResource = (item) => {
  setState(prevState => ({
    ...prevState,
    linkResource: true,
    selecteditem: item
  }));
  props.fetchNestedItems(item.id,1)
}

  const onCloseResource = () => {
    setState(prevState => ({
      ...prevState,
      linkResource: false,
    }));
    onCloseModal();
  }

  const fetchMoreItems = () => {
    fetchData(props)
  }

  const searchItems = text => {
    setState(prevState => ({
      ...prevState,
      searchText: text
    }))
  }

  const fetchData = (nextProps) => {
    let items = nextProps.drivers.filter(row =>
      (row.id + '' + row.uniqueId + '' + row.name)
        .toLowerCase()
        .includes((state.searchText || '').toLowerCase())
    )

    setState(prevState => ({
      ...prevState,
      itemPagination: {
        total: items.length,
        items
      }
    }))
  }

  const removedItem = (item) => {
    instance({
      url: `/api/drivers/${item.id}`,
      method: `DELETE`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        // if (response.status === 204) {
        props.dispatch(removeDriver(item))
        //onCloseModal()
        fetchMoreItems()
        setState(prevState => ({
          ...prevState,
          isVisable: true,
          showItemDetail: false,
          activeOperation: '',
          selecteditem: '',
          onDeleteConfirmation: false,
          driverAddress: '',
          driverLat: '',
          driverLon: ''
        }))
        setisDeleteSucessChild(true)
        props.dispatch(
          Notifications.success({
            message: props.translate('driverIsDeleted'),
            autoDismiss: 10
          })
        )
        props.history.push('/drivers')
 
      })
      .catch(error => { errorHandler(error, props.dispatch) })
  }

  const editItem = async (item) => {
    await onCloseResource()
    setState(prevState => ({
      ...prevState,
      // isVisable: true,
      isVisable: !state.isVisable,
      selecteditem: item,
      activeOperation: 'edit',
      driverAddress: '',
      driverLat: item.attributes.driverLat || '',
      driverLon: item.attributes.driverLon || '',
      showItemDetail: false
    }))
  }

  const addItem = () => {
    props.history.push('/drivers')
    setState(prevState => ({
      ...prevState,
      isVisable: true,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: 'add',
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    }))
  }

  const onCloseModal = () => {
    setState(prevState => ({
      ...prevState,
      isVisable: false,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: '',
      onDeleteConfirmation: false
    }))
    props.history.push('/drivers')
  }

  const selecteItem = async (item) => {
    await onCloseResource()
    props.dispatch(setTrackId(0))
    setState(prevState => ({
      ...prevState,
      allDriverLocation: {},
      showItemDetail: true,
      isVisable: false,
      selecteditem: item,
      selecteditemId: item.id,
      activeOperation: 'details',
      trackersApiResponce: false,
      multiTrackers: '',
      driverLocation: null,
      animCount: 0,
      tracking: true,
      applied: false
    }))
    getMultiDevice(item.id)
    calculate(props);
  }

  const [parentId,setParentId]=useState(0)
  const [isDeletedSuccessChild,setisDeleteSucessChild]=useState(false)
 const getParentIdFromListRow=(id)=>{
setParentId(id)
 }
 useEffect(()=>{
  if(isDeletedSuccessChild){
    if(parentId!==0){
      props.fetchNestedItems(parentId)
setParentId(0)
    }
    setisDeleteSucessChild(false)
  }
 },[isDeletedSuccessChild,parentId])

 const checkZoom = () => {
    if (state.applied === true) {
      setState(prevState => ({
        ...prevState, tracking: false, applied: false, animCount: 0 })) 
        props.dispatch(setTrackId(0))
      }
      setState(prevState => ({
        ...prevState, allDriverLocation: {} })) 
        calculate(props)
    }

 const calculate = n => {
    if (n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
      const list = {};
      const ids = n.drivers.map(d => { list[d.id] = d; return d.id })
      const drivers = {};
      Object.values(n.deviceRelatedData).map(d => {
        if (ids.includes(d.driverId) && (props.allComputedAttributes && props.allComputedAttributes.length && props.allComputedAttributes.includes(d.id) || d.deviceAttributes.fixDriverId)) {
          drivers[d.driverId] = d; drivers[d.driverId].driver = list[d.driverId];
          drivers[d.driverId].attributes.fixDriverId = d.deviceAttributes.fixDriverId;
          if (d && d.attributes && d.attributes.trailerUniqueId) {
            drivers[d.driverId].trailer = n.trailers.find(t => {
              return parseInt(t.uniqueId) === parseInt(d.attributes.trailerUniqueId)
            })
          }
        }
        return null
      })

      setState(prevState => ({
        ...prevState, allDriverLocation: drivers }))
    }
  }

  const getPostion = (address, latlng) => {
    if (address) {
      setState(prevState => ({
        ...prevState,
        driverAddress: address,
        driverLat: latlng.lat,
        driverLon: latlng.lng
      }))
    }
  }

 const getMultiDevice = id => {
    if (id) {
      if (map && props.deviceRelatedData && Object.values(props.deviceRelatedData).length) {
        const driverLocation = Object.values(props.deviceRelatedData).find(d => d && d.driverId === id && props.allComputedAttributes)
        setState(prevState => ({
          ...prevState, driverLocation: driverLocation || null, animCount: 0 }))
        if (driverLocation) {
          props.dispatch(setTrackId(driverLocation.id));
        }
        else {
          props.dispatch(setTrackId(0));
          map.setZoom(3);
        }
      }
      setState(prevState => ({
        ...prevState,
        multiTrackers: [],
        trackersApiResponce: true
      }))
    }
  }

 const onCancel = () => {
  setState(prevState => ({
    ...prevState,
      onDeleteConfirmation: false
    }))
  }

 const onRemovedItem = item => {
    setState(prevState => ({
      ...prevState,
      selecteditem: item,
      onDeleteConfirmation: true
    }))
  }

    let crs = {}
    if (['yandexMap', 'yandexSat'].includes(props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }

    const body = [
      <>
        <ZoomControl position={'bottomright'} />
      </>
    ]

    const position = [state.lat, state.lng]
    const thisMap = [
      <MapContainer
        key={1}
        ref={mapReference}
        onZoomAnim={checkZoom}
        zoomControl={false}
        bounds={
          state.bounds && state.bounds.length
            ? state.bounds
            : null
        }
        // boundsOptions={setBoundOptions}
        style={{ height: props.height, width: props.width }}
        center={position}
        zoom={state.zoom}
        minZoom={state.minZoom}
        maxZoom={props.mapLayer.maxZoom}
        maxNativeZoom={props.mapLayer.maxZoom}
        {...crs}
      >
        {state.pointer}
        {body}

        {state.allDriverLocation && Object.keys(state.allDriverLocation).length ?

          Object.values(state.allDriverLocation).map(row => <Marker
            key={row.id}
            position={{ lat: row.latitude, lng: row.longitude, updated: moment(row.serverTime) }}
            rotationAngle={0}
            rotationOrigin='center'
            animationTime={state.animCount > 0 && state.applied === true & props.trackId === row.id ? row.animationTime : 0}
            icon={L.divIcon({
              iconUrl:
                '/assets/category/default/' +
                (row.category || 'default') +
                'top.svg',
              iconSize: [50, 50],
              iconAnchor: [25, 25],
              tooltipAnchor: [0, -20],
              className: 'custom-marker',
              html: `<img
                  style="transform: rotate(${row.course}deg)"
                    src=
                      '/assets/category/default/${row.category ||
                'default'}top.svg'
                    
                    alt=''
                  />`
            })}
            iconSize={[50, 50]}
          >
            <Tooltip direction={'top'}>
              <MapTooltip
                themecolors={props.themecolors}
                position={row}
                device={row}
                driver={row.driver}
                trailer={row.trailer}
                driver2={state.selecteditem}
                logInUser={props.logInUser}
                translate={props.translate}
              />
            </Tooltip>
          </Marker>) : null}
        <TileLayer
          {...props.mapLayer}
          minZoom={state.minZoom}
        />
      </MapContainer>
    ]


    return (
      <div>
        <Layout
          {...props}
          addDriver={addItem}
          editItem={editItem}
          removedItem={onRemovedItem}
          selecteItem={selecteItem}
          fetchMoreItems={fetchMoreItems}
          classFromChildren={!state.isVisable ? 'no-padding' : 'has-padding'}
          itemPagination={{ ...state.itemPagination }}
          searchItems={searchItems}
          allDriverLocation={state.allDriverLocation}
          showResources={showResources}
          onEditResource={onEditResource}
          onLinkResource={onLinkResource}
          addResource={addResource}
          resourceList={state.resourceList}
          translate={props.translate}
          getParentIdFromListRow={getParentIdFromListRow}
        >

          {!state.isVisable ? <div>
            {['osm', ''].includes(props.mapLayer.id) ? thisMap : null}
            {['carto'].includes(props.mapLayer.id) ? thisMap : null}
            {['gccStreet'].includes(props.mapLayer.id) ? thisMap : null}
            {['googleTerrain'].includes(props.mapLayer.id)
              ? thisMap
              : null}
            {['googleSatellite'].includes(props.mapLayer.id)
              ? thisMap
              : null}
            {['googleHybrid'].includes(props.mapLayer.id) ? thisMap : null}
            {['googleRoad'].includes(props.mapLayer.id) ? thisMap : null}
            {['baidu'].includes(props.mapLayer.id) ? thisMap : null}
            {['yandexMap', 'yandexSat'].includes(props.mapLayer.id) ? thisMap : null}
          </div> : null}

          {state.showItemDetail && (
            <DriversModal
              allDriverLocation={state.allDriverLocation}
              logInUser={props.logInUser}
              onCloseModal={onCloseModal}
              selecteditem={state.selecteditem}
              showItemDetail={state.showItemDetail}
              devicesIcons={images}
              trackersApiResponce={state.trackersApiResponce}
              multiTrackers={state.multiTrackers}
              currentLocation={state.driverLocation}
              devices={props.devices}
              resourceList={state.resourceList}
              translate={props.translate}
            />
          )}
          <ResourceModal
            changeResource={props.changeResource}
            selectedResourse={state.selecteditem}
            activeOperation={state.activeOperation}
            onCloseResource={onCloseResource}
            itemPagination={state.itemPagination && state.itemPagination.items}
            assignItem={props.assignItem}
            unassignItem={props.unassignItem}
            fetchNestedItems={props.fetchNestedItems}
            nestedResources={props.nestedResources}
            translate={props.translate}
            linkResource={state.linkResource}
            themecolors={props.themecolors}
            itemType='Driver'
            title='sharedDriver'
          />
          {!state.showItemDetail ? <div className='main-content-page'>
            
            {state.isVisable && ['add'].includes(state.activeOperation) ? (
              <Grid
                container
                spacing={0}
                className='driver-page-content'
                style={{
                  background: props.themecolors.backgroundColor,
                  color: props.themecolors.textColor
                }}
              >
                <Grid item xs={12} md={7}>
                  <DriversModal
                    onCloseModal={onCloseModal}
                    activeOperation={state.activeOperation}
                    selecteditem={state.selecteditem}
                    selectedAddress={state.driverAddress}
                    driverLat={state.driverLat}
                    driverLon={state.driverLon}
                    trackersApiResponce={state.trackersApiResponce}
                    multiTrackers={state.multiTrackers}
                    resourceList={state.resourceList}
                    getMultiDevice={getMultiDevice}
                    fetchMoreItems={fetchMoreItems}
                    translate={props.translate}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <MarkerMap
                    showMarker={true}
                    getPostion={getPostion}
                    zoom={0}
                    lat={state.driverLat || 0}
                    lng={state.driverLon || 0}
                  />
                </Grid>
              </Grid>
            ):
            <div style={{}}>
              {state.isVisable && ['edit'].includes(state.activeOperation)?(
                <>
                <MainUnitModal {...props} {...state}
       onCloseModal={onCloseModal}
       activeOperation={state.activeOperation}
       selecteditem={state.selecteditem}
       selectedAddress={state.driverAddress}
       driverLat={state.driverLat}
       driverLon={state.driverLon}
       trackersApiResponce={state.trackersApiResponce}
       multiTrackers={state.multiTrackers}
       resourceList={state.resourceList}
       getMultiDevice={getMultiDevice}
       fetchMoreItems={fetchMoreItems}
       translate={props.translate}
                />
                </>
              ):null}
              </div>}
          </div> : null}
          {state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={onCancel}
              onOk={() => removedItem(state.selecteditem)}
              title={props.translate('areYouWantToDelete')}
              children={state.selecteditem.name}
            />
          )}
        </Layout>
      </div>
    )
}

const mapStateToProps = state => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({ item, deviceIds }) => {
    if (deviceIds && item.attribute === 'driverUniqueId') {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null
  })
  return {
    ServerSetting: state.ServerSetting,
    drivers: state.drivers,
    isDriverLoad: state.isDriverLoad,
    trailers: state.trailers,
    devices: state.devices,
    deviceRelatedData: state.deviceRelatedData,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    trackId: state.trackId,
    allComputedAttributes: driverIdsList,
    mapLayer: state.mapLayer
  }
}

export default connect(mapStateToProps)(withLocalize(withResources(Drivers, 'Driver')))