import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { checkPrivileges } from '../../Helpers';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { DriversModal } from '../../Components/Drivers/driversModal';
import AddDriverUserModal from '../../Components/Drivers/addDriverUserModal';

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ color: 'inherit', ...other.style }}
        >
            {children}
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const MainUnitModal = (props) => {
    const [state, setState] = useState({
        // viewScreen: null,
        currentTab: 'user',
        // currentId: 0,
        // selecteditem: null,
        // rowsPerPage: 5,
    });

    // useEffect(() => {
    //     if (props.id) {
    //         setState((prevState) => ({ ...prevState, viewScreen: 'edit' }));
    //         if (props.id && parseInt(state.currentId) !== parseInt(props.id) && props.drivers.length) {
    //             setState((prevState) => ({
    //                 ...prevState,
    //                 currentId: parseInt(props.id),
    //             }));

    //             props.drivers.forEach((group) => {
    //                 if (parseInt(group.id) === parseInt(state.currentId)) {
    //                     setState((prevState) => ({ ...prevState, selecteditem: group }));
    //                 }
    //             });
    //         }
    //     }
    //     if (props.hash) {
    //         const hash = locations.indexOf(props.hash);
    //         if (hash !== -1) {
    //             setState((prevState) => ({ ...prevState, currentTab: props.hash.substring(1, -1) }));
    //         }
    //     }
    // }, [props.id, props.hash, state.currentId, props.drivers]);

    const tabChange = (event, currentTab) => {
        setState((prevState) => ({ ...prevState, currentTab }));
    };


    const { currentTab } = state;
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical';
    const selecteddevices = [...props.selecteddevices];

    selecteddevices.forEach((n) => {
        n.parent = n.parentId === parseInt(props.id);
    });

    return (
        <Fragment>
            <h4 className="tab-title">{state.resFormData ? state.resFormData.name : 'Drivers'}</h4>
            <div className={'main-unit-modal-bg clearfix'} style={{ display: 'flex' }}>
                <div style={{ maxWidth: 264, minWidth: 220 }}>
                    <Tabs
                        orientation={orientation}
                        variant="scrollable"
                        scrollButtons="auto"
                        value={currentTab}
                        onChange={tabChange}
                        aria-label="Vertical tabs example"
                        className="custom-tabs"
                        textColor="#FFFFFF"
                        TabIndicatorProps={{ hidden: true }}
                    >
                        <Tab
                            value="user"
                            classes={{
                                root: 'common-tab',
                                selected: 'tab-selected',
                                wrapper: 'tab-wrapper',
                            }}
                            label={
                                <span className="menu_item_text">
                                    {props.translate('basic')} <ArrowRightIcon className="tab_menu_icon" />
                                </span>
                            }
                            {...a11yProps('basic')}
                        />
                        {checkPrivileges('driverRegister') && (
                            <Tab
                                value="device"
                                classes={{
                                    root: 'common-tab',
                                    selected: 'tab-selected',
                                    wrapper: 'tab-wrapper',
                                }}
                                label={
                                    <span className="menu_item_text">
                                        {props.translate('User Link')} <ArrowRightIcon className="tab_menu_icon" />
                                    </span>
                                }
                                {...a11yProps(1)}
                            />
                        )}
                    </Tabs>
                </div>

                {currentTab === 'user' && (
                    <TabPanel value={currentTab} index={'user'} style={{ width: '100%' }}>
                        {props.selecteditem && (
                        <DriversModal
                            {...props}
                        viewScreen={'edit'}
                        activeOperation={'edit'}
                        // selecteditem={selecteditem}
                        // setSelectedItem={setSelectedItem}
                        />
                         )} 
                    </TabPanel>
                )}

                {checkPrivileges('driverRegister') && currentTab === 'device' && (
                    <TabPanel value={currentTab} index={'device'} style={{ width: '100%' }}>
                              <AddDriverUserModal
                            {...props}
                            
                            isUserLinkTab
                        // viewScreen={'edit'}
                        // activeOperation={'edit'}
                        // selecteditem={selecteditem}
                        // setSelectedItem={setSelectedItem}
                        />
                    </TabPanel>
                )}
            </div>
        </Fragment>
    );
};

MainUnitModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapState = (state) => ({
    devices3: state.devices3,
    devices: state.devices,
    groups: state.groups,
    drivers: state.drivers,
    themecolors: state.themeColors,
    selecteddevices: state.selecteddevices,
    logInUsers: state.logInUsers,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(withLocalize(MainUnitModal));