import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Layout from '../../Layout'
// import { withSnackbar } from 'notistack'
import { removeUser } from './../../Actions/Users'
import { getGroups } from './../../Actions/Groups'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import Notifications from 'react-notification-system-redux'
import MainUnitModal from './MainUnitModal'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'

import EmptyState from '../../Components/common/EmptyState'
import { UsersModal } from './../../Components/Users/usersModal'
import { checkPrivileges, errorHandler } from '../../Helpers/index'
import './Style.scss'
import instance from '../../axios'

const deviceDataFormat = {
  id: '',
  attributes: '',
  name: '',
  uniqueId: '',
  status: '',
  disabled: '',
  lastUpdate: '',
  positionId: '',
  groupId: '',
  phone: '',
  model: '',
  contact: '',
  category: '',
  geofenceIds: '',
  devicePassword: ''
}
const Units=(props)=>{
const [state,setState]=useState({
      viewScreen: null,
      // new
      userLimit: false,
      resFormData: { ...deviceDataFormat },
      selecteditem: '',
      remainings: {},
      remainingsDevice: {},
      limitFetech: false,
      resourceList: false,
      editResource: false,
      areas: '',
      users: [],
      searchText: ''
  })
const [isDeleted,setIsDeleted]=useState(false)
  // General From

  const addNewUser =()=> {
    props.history.push('/addUser')
  }
  const removedItem = () => {
    const { selectedItem } = state
    if (selectedItem.id) {
      instance({
        url: `/api/users/${selectedItem.id}`,
        method: 'DELETE'
      })
        .then(() => {
          // if (response.ok) {
            props.dispatch(removeUser(selectedItem))
            //props.enqueueSnackbar(props.translate('userIsRemoved'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            props.dispatch(
              Notifications.success({
                message: props.translate('userIsRemoved'),
                autoDismiss: 10
              })
            )
            setState((prevstate)=>({
              ...prevstate,
              onDeleteConfirmation:false
            }))
            props.history.push('/users')
            setIsDeleted(true)
            onCancel()
        })
        .catch(error => {
          setState((prevstate)=>({
            ...prevstate,
            onDeleteConfirmation:false
          }))
          errorHandler(error,props.dispatch)})

        
    }
  }
  const showResources = () => {
    setState((prevstate)=>({
      ...prevstate,
      resourceList:!prevstate.resourceList
    }))
 
      if(state.resourceList){
        setState((prevstate)=>({
          ...prevstate,
          editResource:false
        }))
      }
    }
    const addResource = () =>{
      setState((prevstate)=>({
        ...prevstate,
        editResource: true,
        selectedResourse: '',
        viewScreen: null
      }))
    }
    const onEditResource = (item) =>{
      setState((prevstate)=>({
        ...prevstate,
        editResource: true,
        selectedResourse: item
      }))

    }
    const onCloseResource = () =>{
      setState((prevstate)=>({
        ...prevstate,
        editResource: false
      }))
   
    }

  const onCloseModal = () => {
    setState((prevstate)=>({
      ...prevstate,
      viewScreen: ""
    }))

  props.history.push('/users')
  }

  const fetchUserLimit =(value)=> {
    setState((prevstate)=>({
      ...prevstate,
      limitFetech:value,
      remainings:{}
    }))
  }
  useEffect(()=>{
    checkUserLimit()
    },[state.limitFetech])
    

  // this call from render where check the limit of users
  const checkUserLimit = () => {
    if (checkPrivileges('userCreate')) {
      if (
        props.logInUsers &&
        props.logInUsers.id &&
        !Object.keys(state.remainings).length &&
        !state.limitFetech
      ) {
        if (props.logInUsers.userLimit !== -1) {
          setState((prevstate)=>({
            ...prevstate,
            limitFetech: true
          }))
            instance({
              url: `/api/users/count`,
              method: 'GET',
              params:{
                userId: props.logInUsers.id
              }
            })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
                .then(params => {
                  setState((prevstate)=>({
                    ...prevstate,
                    remainings: params,
                    userLimit: params.remaningLimit
                  }))
            }).catch(() => {
              // errorHandler(error,props.dispatch)
            })
        } else {
          setState((prevstate)=>({
            ...prevstate,
            userLimit: true
          }))
        }
      } else if (
        props.logInUsers &&
        props.logInUsers.userLimit === -1
      ) {
        setState((prevstate)=>({
          ...prevstate,
          userLimit: true
        }))
      }
    } else {
      setState((prevstate)=>({
        ...prevstate,
        userLimit: false,
        limitFetech: true
      }))
    }
  }


  const onCancel = () => {
    setState((prevstate)=>({
      ...prevstate,
      onDeleteConfirmation: false,
      selectedItem: '',
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationCommand: false
    }))

  }
  const onRemovedUser = item => {
    setState((prevstate)=>({
      ...prevstate,
      onDeleteConfirmation: true,
      selectedItem: item
    }))
  }

  // const onRemovedNotification = item => {
  //   setState((prevstate)=>({
  //     ...prevstate,
  //     notificationForm: { ...item[0] },
  //     onDeleteConfirmationNotification: true
  //   }))
  // }
//   const onRemovedAttribute = item => {

//     setState((prevstate)=>({
//       ...prevstate,
//       computedAttForm: { ...item[0] },
//       onDeleteConfirmationAttributes: true
//     }))

//   }
//  const onRemovedCommand = item => {
//     setState((prevstate)=>({
//       ...prevstate,
//       savedCommandsForm: { ...item[0] },
//       onDeleteConfirmationCommand: true
//     }))

//   }
//   const onRemovedMaintenance = item => {
//     setState((prevstate)=>({
//       ...prevstate,
//       maintenanceForm: { ...item[0] },
//       onDeleteConfirmationMaintenance: true
//     }))
//   }
  useEffect(()=>{
    setState((prevstate)=>({
      ...prevstate,
      users:props.users2
    }))

    if (checkPrivileges('group') && (props.logInUsers.id)) {
      fetchResources(props.logInUsers.id)
    }
  },[props.users,props.logInUsers])
useEffect(()=>{
  if (props.match.params.id) {
    setState((prevstate)=>({
      ...prevstate,
      viewScreen:"edit"
    }))
  }
  if (props.addUserScreen) {
    setState((prevstate)=>({
      ...prevstate,
      viewScreen:"add"
    }))
  }

  if (!props.addUserScreen && !props.match.params.id) {
    setState((prevstate)=>({
      ...prevstate,
      viewScreen:null
    }))
  }
},[props.addUserScreen,props.match.params?.id])

useEffect(()=>{
  if(isDeleted||state.searchText){
    fetchUsers(state.searchText||"", props.users2)
  }
},[state.searchText,isDeleted,props.users2])



 const fetchUsers = (text, users) => {
    const u = users?.filter(u =>
      (u.id + u.name + u.email).toLowerCase().includes(text.toLowerCase())
    )
    setState((prevstate)=>({
      ...prevstate,
      users:u
    }))
  }
  
  const fetchResources = (id) => {
    instance({
      method: 'GET',
      url: `/api/groups/get?userId=${id}&page=${1}&limit=${500}`,
      // cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      // if (response.status === 200) {
        response.data&&response.data.map(item => {
          item.check = false
          return null
        })
        props.dispatch(getGroups(response.data))
      // }
    })
    .catch(() => {
      // errorHandler(error,props.dispatch)
    })
  }
 const searchItems = text => {
  setState((prevstate)=>({
    ...prevstate,
    searchText:text
  }))
  }

  useEffect(()=>{
return()=>{
  setState((prevstate)=>({
    ...prevstate,
    viewScreen: null,
    // new
    userLimit: false,
    resFormData: { ...deviceDataFormat },
    selecteditem: '',
    remainings: {},
    remainingsDevice: {},
    limitFetech: false,

    users: [],
    searchText: ''
  }))
}
  },[])

 

    if (checkPrivileges('user')) {
      
      const pp = {
        ...props,
        users: state.users
      }

      return (
        <Layout
          {...pp}
          addUser={addNewUser}
          removedItem={onRemovedUser}
          userLimit={state.userLimit}
          classFromChildren='has-padding'
          searchItems={searchItems}
          showResources={showResources}
          onEditResource={onEditResource}
          addResource={addResource}
          resourceList={state.resourceList}
        >
          {!state.editResource ? <div
            className='main-content-page'
            style={{
              background: props.themecolors.backgroundColor,
              color: props.themecolors.textColor,
              borderRadius: 6
            }}
          >
            {state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={onCancel}
                onOk={removedItem}
                title={props.translate('areYouWantToDelete')}
                children={state.selectedItem.name}
              />
            )}
            {state.viewScreen === 'add' && state.userLimit ? (
              <UsersModal
                {...pp}
                viewScreen={state.viewScreen}
                onCloseModal={onCloseModal}
                activeOperation={'add'}
                selecteditem={state.resFormData}
                roleTypes={state.roleTypes}
                fetchUserLimit={fetchUserLimit}
                resourceList={state.resourceList}
              />
            ) : (
              <div>
                {state.viewScreen === 'edit' &&
                props.users2.length &&
                props.match.params.id ? (
                  <>
                  <MainUnitModal
                    id={props.match.params.id}
                    history={props.history}
                    hash={props.location.hash}
                    extra={{ fetchUserLimit: fetchUserLimit }}
                  />
                  </>
                ) : (
                  <EmptyState
                    text={props.translate('pleaseSelectedUser')}
                  />
                )}
              </div>
            )}
          </div>:null}
          {state.editResource ? <div className='main-content-page'>
             <div style={{ background: props.themecolors.backgroundColor, color: props.themecolors.textColor, borderRadius: 6 }}>
               {/* <Grid container spacing={1}>
                 <Grid item xs={12} md={12}> */}
                   <ResourceModal
                     changeResource={props.changeResource}
                     selectedResourse={state.selectedResourse}
                     fetchNestedItems={props.fetchNestedItems}
                     editResource={state.editResource}
                     onCloseResource={onCloseResource}
                     itemType='User'
                   />
                 {/* </Grid>
               </Grid> */}
             </div>
           </div> : null}
        </Layout>
      )
    } else {
      return null
    }
  }


const mapState = state => {
  return {
    users2: state.users,
    themecolors: state.themeColors,
    logInUsers: state.logInUsers
  }
}

const mapStateToProps = connect(mapState)
export default mapStateToProps((withLocalize(withResources(Units, 'User'))))