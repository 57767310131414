import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import Draggable from 'react-draggable'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { connect } from 'react-redux'

const draggableId = new Date().getTime()

const PaperComponent = (props) => {
    return (
      <Draggable bounds='parent' handle={'.dragHandle' + draggableId}>
        <Paper {...props} />
      </Draggable>
    )
}

const PaperDefault = (props) => {
  return <Paper {...props} />
}

const CustomDialog = (props) => {

  const [state,setState] = useState({
    open: true,
    fullScreen: false
  })


//  const handleClickOpen = () => {
//     setState(prevState=>({...prevState, open: true }))
//   }

 const handleClose = () => {
  setState(prevState=>({...prevState, open: false }))
   props.onClose()
  }

  const handleScreen = () => {
    let v = state.fullScreen
    setState(prevState=>({...prevState,fullScreen: !v }))
    if (props.onFullScreen) {
      props.onFullScreen(state.fullScreen)
    }
  }

    const { classes } = props

    let BackdropProps = {}

    if (props.invisible === true) {
      BackdropProps = { invisible: true }
    } else {
      BackdropProps = {
        classes: { root: classes&&classes.root }
      }
    }
    return (
      <Dialog
        fullScreen={props.fullScreen || state.fullScreen}
        classes={{
          paperFullScreen: 'dialog-full-screen',
          scrollPaper: 'scroll-auto',
          paperScrollPaper:
            'scroll-' + (props.overflow ? props.overflow : 'auto')
        }}
        open={state.open}
        onClose={handleClose}
        maxWidth={props.maxWidth || 'sm'}
        disableBackdropClick={true}
        PaperComponent={props.draggable ? PaperComponent : PaperDefault}
        BackdropProps={BackdropProps}
        scroll='body'
        fullWidth
      >
        <IconButton
          aria-label='onClose'
          onClick={handleClose}
          color='inherit'
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
            zIndex: 999,
            borderRadius: 0,
            backgroundColor: props.themecolors&&props.themecolors.themeLightColor,
            color: props.themecolors&&props.themecolors.themeLightInverse,
            '&$hover': {
              backgroundColor: props.themecolors&&props.themecolors.themeLightColor
            },
            hover: {}
          }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
        {!props.noFullScreen && (
          <IconButton
            onClick={handleScreen}
            color='inherit'
            style={{
              position: 'absolute',
              top: 5,
              right: 44,
              zIndex: 999,
              borderRadius: 0,
              backgroundColor: props.themecolors&&props.themecolors.themeLightColor,
              color: props.themecolors&&props.themecolors.themeLightInverse,
              '&$hover': {
                backgroundColor: props.themecolors&&props.themecolors.themeLightColor
              },
              hover: {}
            }}
          >
            {!state.fullScreen && <FullscreenIcon fontSize='small' />}
            {state.fullScreen && <FullscreenExitIcon fontSize='small' />}
          </IconButton>
        )}
        {props.title && (
          <DialogTitle
            disableTypography={true}
            themecolors={props.themecolors&&props.themecolors}
            draggable={props.draggable}
            style={{
              backgroundColor: props.themecolors&&props.themecolors.themeLightColor,
              fontSize: 18,
              fontWeight: 500,
              minHeight: props.minHeight || 44,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: props.draggable ? 44 : 'def',
              color: props.themecolors&&props.themecolors.themeLightInverse,
              position:props.importServiceTbl ? 'absolute': 'inherit',
              zIndex:props.importServiceTbl ? 999: ''
            }}
          >
            {props.title}
          </DialogTitle>
        )}
        <DialogContent
          style={{
            padding: props.bodyPadding ? props.bodyPadding : 0,
            minHeight: props.dialogHeight || 'inherit',
            backgroundColor: props.themecolors&&props.themecolors.backgroundColor,
            color:  props.themecolors&&props.themecolors.textColor,
            overflow: props.overflow ? props.overflow : 'auto'
          }}
        >
          {props.draggable && (
            <span
              className={'dragHandle' + draggableId}
              style={{
                position: 'absolute',
                //width: 44,
                height: props.minHeight || 44,
                fontSize: 32,
                cursor: 'move',
                zIndex: 9,
                left: 0,
                top: 0,
                textAlign: 'left',
                color: props.themecolors&&props.themecolors.themeLightInverse,
                right: 0,
                bottom: 0
              }}
            >
              <DragIndicatorIcon style={{ width: 44 }} />
            </span>
          )}
          {props.children}
        </DialogContent>
        {!props.disableFooter && (
          <DialogActions
            style={{
              margin: 0,
              padding: 10,
              backgroundColor:  props.themecolors&&props.themecolors.backgroundColor
            }}
          >
            {props.leftActions}
            {props.attributeChangesMessage && (
              <span style={{ display: 'block', padding: '10px 0' }}>
                {' '}
                {props.attributeChangesMessage}
              </span>
            )}
            {!props.disableCloseBtn &&
              <Button
                className='button-white'
                onClick={handleClose}
                variant='outlined'
                size='small'
              >
              {props.cancelText || props.CancelText || 'Cancel'}
            </Button>}
            {props.isVisableBtn && props.headerActions}
        {props.isUserDriver&&<Button disabled={!props.isVisableUserSubmit} onClick={props.submitUserForm}>Submit</Button>}
          </DialogActions>
        )}
      </Dialog>
    )
  }

CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapState = state => ({
	themecolors: state.themeColors 
 })
 
 const mapStateToProps = connect(mapState)
 export default mapStateToProps(CustomDialog);
