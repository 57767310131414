import React, { useState, useEffect } from "react";
import Stepper from "./Stepper";
import moment from "moment";
import DateRangePicker from "./DateRangePicker";
import { Grid } from "@mui/material";
import Loader from "../../Layout/Loader";
import VehicleSelector from "../Devices/VehicleSelector";
import Scrollbar from "react-scrollbars-custom";

const DeliveryNoteFilter = (props) => {
  const [state, setState] = useState({
    from: "",
    to: "",
    selectedDate: "",
    vehicleId: [],
    loader: false,
    anchorEl: null,
    selectedvehicleId: [],
    steps: [
      { id: 1, title: "selectServiceTimeControl" },
      { id: 2, title: "selectVehicles" },
    ],
    activeStep: 0,
    reportType: 0,
    isSubmitBtn: false,
  });

  const showDates = (e, date) => {
    let timezone = "Asia/Dubai";
    if (
      props.ServerSetting?.attributes?.timezone ||
      props.logInUser?.attributes?.timezone
    ) {
      timezone =
        props.logInUser?.attributes?.timezone ||
        props.ServerSetting?.attributes?.timezone;
    }
    moment.tz.setDefault(timezone);

    let formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString(),
    };

    let timeFormat = props.logInUser?.twelveHourFormat ? "hh:mm A" : "HH:mm";

    setState((prevState) => ({
      ...prevState,
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        moment(date.endDate).format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    }));
  };

  const handleChange = (name) => {
    setState((prevState) => ({
      ...prevState,
      vehicleId: name,
    }));
  };

  useEffect(() => {
    const selectedvehicleId = state.vehicleId
      .map((value) => (value ? `&vehicleId=${value}` : ""))
      .join("");
    setState((prevState) => ({ ...prevState, selectedvehicleId }));
  }, [state.vehicleId]);

  const goToNextStep = (step, type, index) => {
    if (step === 2) {
      props.formSubmit(state);
    } else {
      validatedCurrentStep(step, type, index);
    }
  };

  const goToBackStep = () => {
    setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
    }));
  };

  const validatedCurrentStep = (step, type, index) => {
    let validated = false;
    let errorMsg = null;
    let updatedSteps = [...state.steps];

    if (step === 1) {
      validated = state.to && state.from;
      errorMsg = validated
        ? null
        : "Please select 'Date' from above";
    } else if (step === 2) {
      validated = true;
    }

    if (validated) {
      setState((prevState) => ({
        ...prevState,
        activeStep: index + 1,
        isSubmitBtn:
          prevState.activeStep === 1 && index + 1 === 2
            ? true
            : prevState.isSubmitBtn,
      }));
    }

    setState((prevState) => ({
      ...prevState,
      steps: updatedSteps.map((s) =>
        s.id === step ? { ...s, errorMsg } : s
      ),
    }));
  };

  const getStepContent = (id) => {
    switch (id) {
      case 1:
        return (
          <Grid container className="form-group sidebar-bg-layer">
            <Grid item xs={12}>
              <DateRangePicker
                fill
                error={props.timeError || false}
                selectedDate={state.selectedDate}
                label={
                  props.translate("reportFrom") +
                  " " +
                  props.translate("reportTo")
                }
                onEvent={showDates}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container className="form-group">
            <Grid item xs={12}>
              <VehicleSelector
                api="vehicle"
                fill
                hideIcons
                isClearable
                placeholder={props.translate("searchVehicle")}
                onChange={handleChange}
                value={state?.vehicleId || []}
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <div className="geofence-sidebar-list">
      {!props.loader ? (
        <Scrollbar>
          <form style={{ padding: "16px 0 16px 10px" }}>
            <Grid container className="form-group">
              <Grid item xs={props.gridSize ? props.gridSize : 12}>
                <Stepper
                  translate={props.translate}
                  activeStep={state.activeStep}
                  getStepContent={getStepContent}
                  steps={state.steps}
                  handleNext={goToNextStep}
                  handleBack={goToBackStep}
                  isSubmitBtn={state.isSubmitBtn}
                  reportType
                  themecolors={props.themecolors}
                />
              </Grid>
            </Grid>
          </form>
        </Scrollbar>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DeliveryNoteFilter;
